import axios from "@/axios-auth";
import { Notify } from "quasar";
import store from '@/store/store';

let moment = require("moment");

const state = {
  graphReceita: {},
  fetchUser: {
    buscando: false,
    db: {}
  },
  percentCards: {
    buscando: false,
    diferencaAtivosQuant: 0,
    diferencaInativosQuant: 0,
    diferencaAtivosPercent: 0,
    diferencaInativosPercent: 0,
    percentAtivos: 0,
    percentInativos: 0
  },
  getRatioAI: {
    imprime: null
  },
  dtanterior: {},
  loadingDash: false,
  chartDataAtivos: [],
  layout: [],
  openDialogAdicionarCard: false,
  openDialogSistemaGrid: false,
};

const mutations = {
  graphReceita: (state, userData) => {
    state.graphReceita = userData.graphReceita;
  },
  fetchUser: (state, userData) => {
    state.fetchUser.buscando = userData.buscando;
    state.fetchUser.db = userData.db;
  },
  percentCards: (state, userData) => {
    state.percentCards.buscando = userData.buscando;
    state.percentCards.diferencaAtivosQuant = userData.diferencaAtivosQuant;
    state.percentCards.diferencaInativosQuant = userData.diferencaInativosQuant;
    state.percentCards.diferencaAtivosPercent = userData.diferencaAtivosPercent;
    state.percentCards.diferencaInativosPercent =
      userData.diferencaInativosPercent;
    state.percentCards.percentAtivos = userData.percentAtivos;
    state.percentCards.percentInativos = userData.percentInativos;
  },
  getRatioAI: (state, userData) => {
    state.getRatioAI.imprime = userData.imprime;
  },
  dtanterior: (state, userData) => {
    state.dtanterior = userData.dtanterior;
  },
  loadingDash: (state, userData) => {
    state.loadingDash = userData.loadingDash;
  },
  // handlerSocios: (state, userData) => {
  //   state.handlerSocios = userData.handlerSocios
  // },
  chartDataAtivos: (state, userData) => {
    state.chartDataAtivos = userData;
  },
  layout: (state, userData) => {
    state.layout = userData;
  },
  openDialogAdicionarCard: (state, userData) => {
    state.openDialogAdicionarCard = userData;
  },
  openDialogSistemaGrid: (state, userData) => {
    state.openDialogSistemaGrid = userData;
  },
};

const actions = {
  graphReceita: ({ commit }, userData) => {
    commit("graphReceita", {
      graphReceita: userData
    });
  },
  fetchUser: ({ commit, dispatch }, userData) => {

    let empresa = store.getters.dataEmpresa.id === undefined
      ? localStorage['empresa_atual'] : store.getters.dataEmpresa.id;

    if (empresa === undefined) {
      return;
    }

    if (!localStorage["token" + empresa]) {
      return;
    }
    let query = {};
    if (userData) {
      query.dtini = userData.dtini;
      query.dtfim = userData.dtfim;
    }
    commit("fetchUser", {
      buscando: true
    });
    axios
      .get("/users/dashboard", {
        params: query
      })
      .then(res => {
        if (!res.data.success) {
          throw res.data;
        }

        commit("fetchUser", {
          db: res.data,
          buscando: false
        });

        let layout = state.fetchUser.db.layoutDashboard;
        for (let i in layout) {
          if (i === 5) {
            layout[i].h =
              layout[i].h + state.fetchUser.db.assinaturaItems.length;
          }
        }

        commit("layout", layout);
        dispatch("dashClientes");
        if (userData && userData.callback) userData.callback();
      })
      .catch(() => {
        Notify.create({
          message: "Não foi possível contactar, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
      });
  },
  dashClientes: ({ commit, dispatch, state }, userData) => {
    commit("loadingDash", {
      loadingDash: true
    });
    let query = {};
    if (userData) {
      query.dtini = userData.dtini;
      query.dtfim = userData.dtfim;
    } else {
      query.dtini = moment.utc(state.fetchUser.db.dtini).format("DD-MM-YYYY");
      query.dtfim = moment.utc(state.fetchUser.db.dtfim).format("DD-MM-YYYY");
    }
    query.tipo = "clientes";
    axios
      .get("/users/dashboard", {
        params: query
      })
      .then(res => {
        if (res.data.success) {
          let tudo = JSON.parse(JSON.stringify(state.fetchUser.db));
          tudo.contadores = res.data.cadastrosCount[0];
          tudo.dtini = res.data.dtini;
          tudo.dtfim = res.data.dtfim;
          tudo.dtini = new Date(tudo.dtini);
          tudo.dtini = tudo.dtini.toISOString();
          tudo.dtfim = new Date(tudo.dtfim);
          tudo.dtfim = tudo.dtfim.toISOString();
          commit("fetchUser", {
            db: tudo,
            buscando: false
          });
          let layout = state.fetchUser.db.layoutDashboard;
          for (let i in layout) {
            if (i === 5) {
              layout[i].h =
                layout[i].h + state.fetchUser.db.assinaturaItems.length;
            }
          }
          commit("layout", layout);
          if (userData) {
            dispatch("getRatioAI");
          } else {
            // dispatch('percentCards')
          }
        }
        dispatch("graphSocios");
      })
      .catch(() => {
        commit("loadingDash", {
          loadingDash: false
        });
      });
  },
  graphSocios: ({ commit, state }) => {
    setTimeout(() => {
      let chartDataAtivos = [
        ["Sócios Ativos", "Sócios Inativos"],
        ["Sócios", state.fetchUser.db.contadores["clientes_ativos"]],
        ["Não Sócios", state.fetchUser.db.contadores["clientes_inativos"]]
      ];
      commit("chartDataAtivos", chartDataAtivos);
      commit("loadingDash", {
        loadingDash: false
      });
    }, 500);
  },
  percentCards: ({ commit, dispatch }) => {
    let ativos = state.fetchUser.db.planos.plano_clientes_ativos;
    let inativos = state.fetchUser.db.planos.plano_clientes_inativos;

    let ativosPercent =
      (state.fetchUser.db.contadores.clientes_ativos / ativos) * 100;
    let inativosPercent =
      (state.fetchUser.db.contadores.clientes_inativos / inativos) * 100;

    let ativosArredondado = parseFloat(ativosPercent.toFixed(0));
    let inativosArredondado = parseFloat(inativosPercent.toFixed(0));

    let ativosDiferencaPercent = 100 - ativosArredondado;
    let inativosDiferencaPercent = 100 - inativosArredondado;

    let ativosDiferencaQuant =
      ativos - state.fetchUser.db.contadores.clientes_ativos;
    let inativosDiferencaQuant =
      inativos - state.fetchUser.db.contadores.clientes_inativos;

    commit("percentCards", {
      diferencaAtivosQuant: ativosDiferencaQuant,
      diferencaInativosQuant: inativosDiferencaQuant,
      diferencaAtivosPercent: ativosDiferencaPercent,
      diferencaInativosPercent: inativosDiferencaPercent,
      percentAtivos: ativosArredondado,
      percentInativos: inativosArredondado,
      buscando: false
    });
    dispatch("getRatioAI");

    dispatch("graphReceita", state.fetchUser.db.receitas);
  },
  getRatioAI: ({ commit }) => {
    let i = state.fetchUser.db.contadores.clientes_inativos;
    let a = state.fetchUser.db.contadores.clientes_ativos;
    let result = a - i;
    commit("getRatioAI", {
      imprime: result
    });
  },
  openDialogAdicionarCard: ({ commit }, userData) => {
    commit("openDialogAdicionarCard", userData);
  },
  openDialogSistemaGrid: ({ commit }, userData) => {
    commit("openDialogSistemaGrid", userData);
  },
  postLayout: ({ commit, dispatch }, userData) => {
    axios
      .post("/users/dashboard", {
        layoutDashboard: userData.layout,
        reajustar: userData.reajustar,
      })
      .then(res => {
        if (!res.data.success) {
          Notify.create({
            message:
              "Algo incomum aconteceu. Atualize a página e tente novamente.",
            color: "orange",
            position: "top-right"
          });
        }
        if (userData.reload) {
          dispatch("fetchUser", {
            callback() {
              commit("layout", res.data.layoutDashboard);
            }
          });
        } else {
          commit("layout", res.data.layoutDashboard);
        }

        if (userData.callback) userData.callback();
      })
      .catch(() => {
        Notify.create({
          message:
            "Não foi possível ajustar os cartões do Dashboard, ou você não possui permissão!",
          color: "red",
          position: "top-right"
        });
        if (userData.callback) userData.callback();
      });
  },
  trocaVisibilidadeCard: ({ dispatch, getters }, userData) => {
    const layout = JSON.parse(JSON.stringify(getters.layout)); // limpa os observadores do VueJS

    layout.forEach(item => {
      if (item.i == userData.cardId) {
        item.visivel = item.visivel === true || item.visivel === "true" ? false : true;
      }
    });

    dispatch("postLayout", {
      layout: layout,
      reload: userData.reload,
    });

    if (userData.callback) userData.callback();
  }
};

const getters = {
  graphReceita: state => {
    return state.graphReceita;
  },
  fetchUser: state => {
    return state.fetchUser;
  },
  percentCards: state => {
    return state.percentCards;
  },
  getRatioAI: state => {
    return state.getRatioAI.imprime;
  },
  loadingDash: state => {
    return state.loadingDash;
  },
  chartDataAtivos: state => {
    return state.chartDataAtivos;
  },
  layout: state => {
    return state.layout;
  },
  openDialogAdicionarCard: state => {
    return state.openDialogAdicionarCard;
  },
  openDialogSistemaGrid: state => {
    return state.openDialogSistemaGrid;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
