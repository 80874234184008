<template>
  <div id="openDialogNovoFaturamentoAutomatico">

    <q-dialog
      v-model="openDialogNovoFaturamentoAutomatico"
      persistent
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card class="novo-faturamento-automatico-card q-py-md">
        <q-card-section class="row items-center q-pt-none">
          <div class="text-h6">
            {{ faturamentoAutomatico.id ? 'Editar' : 'Novo' }}
            <q-toggle
              v-model="faturamentoAutomatico.ativo"
              color="positive"
              class="right"
              label="Ativo"
            />
          </div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            class="q-ml-lg"
            @click="closeModalAdd()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-x-md">
            <div class="col-12">
              <q-checkbox
                color="green"
                label="Realizar o faturamento diariamente"
                v-model="faturamentoAutomatico.faturamento_diario"
              />
            </div>

            <div class="col-12 col-md-6">
              <div v-if="faturamentoAutomatico.faturamento_diario">
                <q-input
                  bottom-slots
                  label="X dias antes do vencimento"
                  type="number"
                  :error="Boolean(errors.dias_antes)"
                  v-model="faturamentoAutomatico.dias_antes"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.dias_antes"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

              <div v-else>
                <q-input
                  bottom-slots
                  label="Dia de execução"
                  type="number"
                  :rules="[value => (value > 0 || value == '') || '']"
                  :error="Boolean(errors.dia_execucao)"
                  v-model="faturamentoAutomatico.dia_execucao"
                >
                  <template v-slot:error>
                    <span
                      v-for="(error, i) in errors.dia_execucao"
                      :key="error + i"
                      >{{ error }}</span
                    >
                  </template>
                </q-input>
              </div>

            </div>

            <div class="col-12 col-md-6">
              <q-select
                emit-value
                map-options
                bottom-slots
                label="Horário de execução"
                :options="horarios"
                :error="Boolean(errors.horario_execucao)"
                v-model="faturamentoAutomatico.horario_execucao"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.horario_execucao"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>

            <div v-if="!faturamentoAutomatico.faturamento_diario" class="col-12 col-md-6">
              <q-input
                bottom-slots
                label="Dia de vencimento padrão"
                :error="Boolean(errors.dia_vencimento)"
                v-model="faturamentoAutomatico.dia_vencimento"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.dia_vencimento"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div v-if="!faturamentoAutomatico.faturamento_diario" class="col-12 col-md-6">
              <q-select
                emit-value
                map-options
                bottom-slots
                label="Mês de vencimento padrão"
                hint="Mês referente a data de execução"
                :options="opcoesMesVencimento"
                :error="Boolean(errors.mes_vencimento)"
                v-model="faturamentoAutomatico.mes_vencimento"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.mes_vencimento"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-select>
            </div>

            <div v-if="!faturamentoAutomatico.faturamento_diario" class="col-12">
              <q-checkbox
                color="green"
                label="Utilizar data de início e fim"
                v-model="faturamentoAutomatico.utilizar_inicio_fim"
              />
            </div>

            <div class="col-12 col-md-6" v-show="faturamentoAutomatico.utilizar_inicio_fim">
              <q-input
                bottom-slots
                label="Dia de início"
                :error="Boolean(errors.dia_inicial)"
                v-model="faturamentoAutomatico.dia_inicial"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.dia_inicial"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-12 col-md-6" v-show="faturamentoAutomatico.utilizar_inicio_fim">
              <q-input
                bottom-slots
                label="Dia de fim"
                :error="Boolean(errors.dia_final)"
                v-model="faturamentoAutomatico.dia_final"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.dia_final"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-checkbox
                color="green"
                label="Personalizar descrição"
                v-model="faturamentoAutomatico.personalizar_descricao"
              />
            </div>

            <div class="col-12" v-show="faturamentoAutomatico.personalizar_descricao">
              <q-select
                emit-value
                map-options
                label="Variáveis"
                hint="As variáveis da descrição são referentes ao vencimento"
                :options="variaveis"
                v-model="variavel"
              />
            </div>

            <div class="col-12" v-show="faturamentoAutomatico.personalizar_descricao">
              <q-input
                bottom-slots
                label="Descrição"
                hint="Substituir Descrição na Fatura"
                :error="Boolean(errors.descricao_personalizada)"
                v-model="faturamentoAutomatico.descricao_personalizada"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in errors.descricao_personalizada"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Salvar"
            color="green"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingAdd"
            @click="salvar()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>

<script>
import axios from '@/axios-auth';
import { bus } from "@/main";
import { mapGetters } from 'vuex';
import moment from "moment";

export default {
  name: "modal-novo-faturamento-automatico",
  data() {
    return {
      errors: {},
      loadingAdd: false,
      variavel: null,
      variaveis: [
        {
          label: 'Ano Atual',
          value: '{anoAtual}',
        },
        {
          label: 'Ano Anterior',
          value: '{ultimoAno}',
        },
        {
          label: 'Mês Atual',
          value: '{mesAtual}',
        },
        {
          label: 'Mês Anterior',
          value: '{ultimoMes}',
        },
        {
          label: 'Próximo Mês',
          value: '{proximoMes}',
        },
        {
          label: 'Nome do Mês Atual - 3 Caracteres',
          value: '{mesAtualNome}',
        },
        {
          label: 'Nome do Mês Anterior - 3 Caracteres',
          value: '{ultimoMesNome}',
        },
        {
          label: 'Nome do Próximo Mês - 3 Caracteres',
          value: '{proximoMesNome}',
        },
      ],
      opcoesMesVencimento: [
        {
          label: 'Mês Atual',
          value: 0,
        },
        {
          label: 'Próximo Mês',
          value: 1,
        },
      ],
    };
  },
  methods: {
    closeModalAdd() {
      this.$store.commit("setOpenDialogNovoFaturamentoAutomatico", false);
    },
    salvar() {
      this.loadingAdd = true;

      const url = this.faturamentoAutomatico.id
      ? `/financeiro/edit-faturamento-automatico/${this.faturamentoAutomatico.id}`
      : '/financeiro/add-faturamento-automatico';
      const data = JSON.parse(JSON.stringify(this.faturamentoAutomatico));

      if  (data.faturamento_diario) {
        data.dia_execucao = 0;
        data.dia_vencimento = 1;
        data.mes_vencimento = 0;
        data.utilizar_inicio_fim = false;
      } else {
        data.dias_antes = null;
      }
      if  (!data.utilizar_inicio_fim) {
        data.dia_inicial = null;
        data.dia_final = null;
      }
      if  (!data.personalizar_descricao) {
        data.descricao_personalizada = null;
      }

      axios
        .post(url, data)
        .then(res => {
          this.loadingAdd = false;

          if (this.notificarSemPermissao(res)) return;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errors = res.data.errors || {};

            return;
          }

          this.closeModalAdd();
          bus.$emit("loadFaturamentoAutomatico");
        })
        .catch(() => {
          this.loadingAdd = false;
          this.$q.notify({
            message: 'Houve um erro ao salvar o faturamento automático',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    notificarSemPermissao(res) {
      const semPermissao = res.error && res.error.response.status === 403;

      if (!semPermissao) {
        return false;
      }

      this.$q.notify({
        message: res.error.response.data.message,
        color: 'red',
        position: 'top-right',
      });

      return true;
    },
  },
  computed: {
    ...mapGetters([
      "faturamentoAutomatico",
      "openDialogNovoFaturamentoAutomatico",
    ]),
    horarios() {
      const horarios = [];

      for (let i = 0; i < 24; i++) {
        const hora = `0${i}`.slice(-2);

        horarios.push({
          label: `${hora}:00`,
          value: i,
        });
      }

      return horarios;
    },
  },
  watch: {
    openDialogNovoFaturamentoAutomatico(value) {
      if (value) {
        this.errors = {};
      }
    },
    variavel(value) {
      if (value) {
        this.faturamentoAutomatico.descricao_personalizada += value;
        this.variavel = null;
      }
    },
    'faturamentoAutomatico.faturamento_diario': function(novo) {
      if(!novo) {
        this.faturamentoAutomatico.dia_execucao = parseInt(moment().add(1, 'day').format('DD'));
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
.novo-faturamento-automatico-card {
  width: 100%;
  max-width: 450px;
}
</style>
