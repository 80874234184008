<template>
  <div id="modalFaturar">
    <q-dialog v-model="OpenDialogFaturar" persistent>
      <q-card style="max-width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Faturamento</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="cancela()" />
        </q-card-section>
        <q-separator />

        <q-card-section class="q-pa-none">
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>{{faturamento.opcoes.clientes_ativos}}</q-item-label>
                <q-item-label caption>Clientes Ativos</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{faturamento.opcoes.total_recorrencias | currency}}</q-item-label>
                <q-item-label caption>Total em Recorrências</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{faturamento.opcoes.total_despesas_eventuais | currency}}</q-item-label>
                <q-item-label caption>Total em Receitas Eventuais</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{faturamento.opcoes.total_recorrencias + faturamento.opcoes.total_despesas_eventuais | currency}}</q-item-label>
                <q-item-label caption>Total</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
            <div class="q-col-gutter-md row">
              <div class="col-12">
                <q-input
                  bottom-slots
                  :error="errorsReceitas.vencimento ? true : false"
                  v-model="saveFaturamento.vencimento"
                  label="Vencimento padrão"
                  @click="$refs.qDateProxy.show();"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="saveFaturamento.vencimento"
                          @input="() => buscarFaturamento('vencimento')"
                          mask="DD/MM/YYYY"
                          :locale="langDate"
                          minimal
                          today-btn
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsReceitas.vencimento" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:hint>
                    Vencimento para Clientes sem dia fixo
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  bottom-slots
                  :error="errorsReceitas.referencia ? true : false"
                  v-model="saveFaturamento.referencia"
                  label="Referência"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsReceitas.referencia" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-checkbox
                  v-model="saveFaturamento.utilizarDtiniDtfim"
                  label="Utilizar data de início e fim"
                  color="green"
                />
              </div>
              <div class="col-md-6" v-if="saveFaturamento.utilizarDtiniDtfim">
                <q-input
                    bottom-slots
                    :error="errorsReceitas.dtini ? true : false"
                    v-model="saveFaturamento.dtini"
                    label="Data de início"
                    @click="$refs.qDtiniProxy.show();"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDtiniProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="saveFaturamento.dtini"
                            @input="() => buscarFaturamento('dtini')"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errorsReceitas.dtini" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
              </div>
              <div class="col-md-6" v-if="saveFaturamento.utilizarDtiniDtfim">
                <q-input
                    bottom-slots
                    :error="errorsReceitas.dtfim ? true : false"
                    v-model="saveFaturamento.dtfim"
                    label="Data de fim"
                    @click="$refs.qDtfimProxy.show();"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDtfimProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="saveFaturamento.dtfim"
                            @input="() => buscarFaturamento('dtfim')"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errorsReceitas.dtfim" :key="error + i">{{error}}</span>
                    </template>
                  </q-input>
              </div>
              <div class="col-12 col-md-12">
                <q-checkbox
                  color="green"
                  label="Personalizar descrição"
                  v-model="saveFaturamento.personalizarDescricao"
                />
              </div>
              <div class="col-12 col-md-12" v-if="saveFaturamento.personalizarDescricao">
                <q-input
                  bottom-slots
                  :error="errorsReceitas.descricao ? true : false"
                  v-model="saveFaturamento.descricao"
                  label="Descrição"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsReceitas.descricao" :key="error + i">{{error}}</span>
                  </template>
                  <template v-slot:hint>
                    Substituir Descrição na Fatura
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-checkbox
                  v-model="saveFaturamento.apenasParcelas"
                  label="Faturar apenas as parcelas"
                  color="green"
                  @input="() => buscarFaturamento()"
                />
              </div>

            </div>
        </q-card-section>

        <q-card-actions class="text-primary column items-start">
          <div style="margin-left: 22px; margin-bottom: 10px;">
            <q-btn-group flat class="items-center">
              <div class="text-black text-weight-bold q-mr-sm" style="width: 95px;">
                Detalhamento
              </div>
              <q-btn
                flat
                dense
                no-caps
                icon="print"
                color="white"
                text-color="black"
                :loading="gerandoRelatorio === 'detalhado'"
                @click="gerarRelatorio('detalhado')"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
              <q-btn
                flat
                dense
                no-caps
                color="white"
                text-color="black"
                icon="fa fa-file-excel-o"
                :loading="exportandoRelatorio === 'detalhado'"
                @click="exportarRelatorio('detalhado')"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </q-btn-group>

            <q-btn-group flat class="items-center">
              <div class="text-black text-weight-bold q-mr-sm" style="width: 95px;">
                Relatório
               Final</div>
              <q-btn
                flat
                dense
                no-caps
                icon="print"
                color="white"
                text-color="black"
                :loading="gerandoRelatorio === 'final'"
                @click="gerarRelatorio('final')"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
              <q-btn
                flat
                dense
                no-caps
                color="white"
                text-color="black"
                icon="fa fa-file-excel-o"
                :loading="exportandoRelatorio === 'final'"
                @click="exportarRelatorio('final')"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
              </q-btn>
            </q-btn-group>
          </div>

          <div class="self-end">
            <q-btn
              flat
              label="Faturar"
              clickable
              @click="saveFaturar"
              :loading="loadFaturamento"
              class="bg-positive text-white"
              style="margin-right: 22px; margin-bottom: 10px"
            >
              <template v-slot:loading>
                <q-spinner color="grey-10" size="1em" />
              </template>
            </q-btn>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Notify, Loading, Dialog } from "quasar";
import moment from "moment";
import axios from "@/axios-auth";

export default {
  name: "modal-faturar",
  data() {
    return {
      gerandoRelatorio: false,
      exportandoRelatorio: false,
    };
  },
  methods: {
    buscarFaturamento(campo = null) {
      let data = JSON.parse(JSON.stringify(this.saveFaturamento));
      this.$store.dispatch("buscarFaturamento", data);

      switch(campo) {
        case "vencimento":
          this.$refs.qDateProxy.hide();
          break;
        case "dtini":
          this.$refs.qDtiniProxy.hide();
          break;
        case "dtfim":
          this.$refs.qDtfimProxy.hide();
          break;
      }
    },
    saveFaturar() {
      Dialog.create({
        title: "Confirma a realização do faturamento?",
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        this.$store.dispatch("saveFaturarReceitas", this.saveFaturamento);
      });
    },
    cancela() {
      this.$store.commit("OpenDialogFaturar", false);
    },
    gerarRelatorio(tipo) {
      this.gerandoRelatorio = tipo;

      let data = {
        vencimento: this.saveFaturamento.vencimento,
        descricao: this.saveFaturamento.personalizarDescricao ? this.saveFaturamento.descricao : null,
        referencia: this.saveFaturamento.referencia,
        dtini: this.saveFaturamento.utilizarDtiniDtfim ? this.saveFaturamento.dtini : null,
        dtfim: this.saveFaturamento.utilizarDtiniDtfim ? this.saveFaturamento.dtfim : null,
        cadastros_ids: this.saveFaturamento.cadastros_ids,
        recorrencias_ids: this.saveFaturamento.recorrencias_ids,
        apenasParcelas: this.saveFaturamento.apenasParcelas,
      };

      axios
        .post(`/financeiro/faturar?relatorio=${tipo}`, data, {
          headers: {
            Accept: "application/json,text/html"
          }
        })
        .then(res => {
          this.gerandoRelatorio = false;

          if (typeof res.data !== "string") {
            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? "green" : "orange",
              position: "top-right",
            });

            this.$store.commit("errorsReceitas", {
              errorsReceitas: res.data.errors || [],
            });
            return;
          }

          this.$store.commit("errorsReceitas", {
            errorsReceitas: [],
          });

          let windowJanela = window.open("about:blank");
          windowJanela.document.write(res.data);
          windowJanela.document.close();
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao visualizar o relatório",
            color: "red",
            position: "top-right"
          });
          this.gerandoRelatorio = false;
        });
    },
    exportarRelatorio(tipo) {
      this.exportandoRelatorio = tipo;

      let data = {
        vencimento: this.saveFaturamento.vencimento,
        descricao: this.saveFaturamento.personalizarDescricao ? this.saveFaturamento.descricao : null,
        referencia: this.saveFaturamento.referencia,
        dtini: this.saveFaturamento.utilizarDtiniDtfim ? this.saveFaturamento.dtini : null,
        dtfim: this.saveFaturamento.utilizarDtiniDtfim ? this.saveFaturamento.dtfim : null,
        cadastros_ids: this.saveFaturamento.cadastros_ids,
        recorrencias_ids: this.saveFaturamento.recorrencias_ids,
        apenasParcelas: this.saveFaturamento.apenasParcelas,
        exportar: true,
      };

      axios
        .post(`/financeiro/faturar?relatorio=${tipo}`, data)
        .then(res => {
          this.exportandoRelatorio = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: 'green',
            position: 'top-right',
          });
          window.location.href = res.data.linkArquivo;
        })
        .catch(() => {
          this.$q.notify({
            message: "Houve um erro ao exportar o relatório",
            color: "red",
            position: "top-right"
          });
          this.exportandoRelatorio = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      "loadFaturamento",
      "saveFaturamento",
      "faturamento",
      "errorsReceitas"
    ]),
    OpenDialogFaturar: {
      get() {
        return this.$store.getters.OpenDialogFaturar;
      },
      set() {}
    }
  },
  watch: {
    "saveFaturamento.vencimento": {
      immediate: true,
      handler(vencimento) {
        if (!vencimento) {
          return;
        }

        this.saveFaturamento.dtini = moment(vencimento, "DD/MM/YYYY").startOf("month").format("DD/MM/YYYY");
        this.saveFaturamento.dtfim = moment(vencimento, "DD/MM/YYYY").endOf("month").format("DD/MM/YYYY");
      },
    },
  },
};
</script>

<style lang="stylus" scoped></style>
