<template>
  <div id="contasParametros">
    <q-table
      title
      :data="contas"
      :columns="columns"
      row-key="name"
      :separator="separator"
      :loading="loadContas"
      no-data-label="Nenhum resultado encontrado."
      :pagination.sync="pagination"
    >
      <template v-slot:bottom>
        <div></div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
            key="agencia"
            class="text-left"
          >{{props.row.agencia}} {{props.row.operacao}} {{props.row.conta}}</q-td>
          <q-td key="titulo"  class="text-left">{{props.row.titulo}}</q-td>
          <q-td key="tipo" class="text-left">{{props.row.tipo}}</q-td>
          <q-td key="ativo" class="text-left">
             <q-icon
              :name="props.row.ativo ? 'check' : 'close'"
              size="24px"
              :class="props.row.ativo ? 'text-positive' : 'text-negative'"
            ></q-icon>
          </q-td>
          <q-td key="created" class="text-center">{{props.row.created | formatDate}}</q-td>
          <q-td class="tdfora">
            <div class="divfora">
              <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                <q-btn
                  @click="modal(props.row)"
                  size="12px"
                  class="bg-primary text-white"
                  icon="edit"
                ></q-btn>
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "contas-parametros",
  data() {
    return {
      separator: "horizontal",
       pagination: {
        rowsPerPage: 0
      },
      columns: [
        {
          name: "agencia",
          required: true,
          label: "Agência - Operação - Conta",
          align: "left",
          field: row => row.agencia,
          sortable: true
        },
        {
          name: "titulo",
          align: "left",
          label: "Título",
          field: row => row.titulo,
          sortable: true
        },
        {
          name: "tipo",
          label: "Tipo",
          field: row => row.tipo,
          sortable: true,
          align: "left"
        },
        {
          name: "ativo",
          label: "Ativo",
          field: row => row.ativo,
          sortable: true,
          align: "left"
        },
        {
          name: "created",
          label: "Criado em",
          field: row => row.created,
          sortable: true,
          align: "center"
        },
        { name: 'tdfora' },
      ],
      operacao: [
        {
          value: "000",
          title: "000 - Sem Operação"
        },
        {
          value: "001",
          title: "001 – Conta Corrente - P.Física"
        },
        {
          value: "002",
          title: "002 – Conta Simples - P.Física"
        },
        {
          value: "003",
          title: "003 – Conta Corrente - P.Jurídica"
        },
        {
          value: "006",
          title: "006 – Entidades Públicas"
        },
        {
          value: "007",
          title: "007 – Dep. Instituições Financeiras"
        },
        {
          value: "013",
          title: "013 – Poupança"
        },
        {
          value: "022",
          title: "022 – Poupança - Pessoa Jurídica"
        },
        {
          value: "023",
          title: "023 – Conta CAIXA Fácil"
        },
        {
          value: "028",
          title: "028 – Poupança Crédito Imobiliário"
        },
        {
          value: "032",
          title: "032 – Conta Investimento - P.Física"
        },
        {
          value: "034",
          title: "034 – Conta Investimento - P.Jurídica"
        },
        {
          value: "043",
          title: "043 – Depósitos Lotéricos"
        }
      ]
    };
  },
  methods: {
    modal(conta) {
      let c = JSON.parse(JSON.stringify(conta));
      for (let i in this.operacao) {
        if (this.operacao[i].value === c.operacao) {
          c.operacao = this.operacao[i];
        }
      }

      let newConta = {
        agencia: c.agencia,
        operacao: c.operacao,
        conta: c.conta,
        tipo: c.tipo,
        isNew: false,
        id: c.id,
        titulo: c.titulo,
        ativo: c.ativo,
        padrao_sangria: c.padrao_sangria,
        padrao_reforco: c.padrao_reforco
      };

      if (c.tipo === "banco-dourasoft") {
        newConta.hasPassword = c.hasPassword;
      }

      this.$store.commit("newConta", newConta);
      this.$store.commit("OpenDialogNovaConta", true);
    }
  },
  computed: {
    ...mapGetters(["contas", "loadContas"])
  },
  mounted() {
    this.$store.dispatch("contas",true);
  }
};
</script>

<style lang="stylus" scoped>
.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
