<template>
  <div id="modalNovaConta">
    <q-dialog v-model="OpenDialogNovaConta" persistent>
      <q-card style="min-width: 300px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">{{newConta.isNew ? 'Nova ' : 'Editar '}} Conta</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-6 col-md-6" id="titulo">
                <q-input
                  v-model="newConta.titulo"
                  label="Titulo"
                  bottom-slots
                  :error="errorsContas.titulo ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsContas.titulo" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-6 col-md-6" id="agencia">
                <q-input
                  v-model="newConta.agencia"
                  maxlength="4"
                  label="Agência"
                  bottom-slots
                  :error="errorsContas.agencia ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsContas.agencia" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-12" >
                <q-select
                  v-model="newConta.operacao"
                  :options="operacao"
                  label="Operação"
                  id="operacao"
                  bottom-slots
                  :error="errorsContas.operacao ? true : false"
                  :option-label="value => value === null ? 'Item Vazio' : value.title"
                  :option-value="value => value === null ? null : value.value"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsContas.operacao" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-6 col-md-6" id="conta">
                <q-input
                  v-model="newConta.conta"
                  label="Conta"
                  bottom-slots
                  maxlength="10"
                  :error="errorsContas.conta ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsContas.conta" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="col-6 col-md-6">
                <q-select
                  v-model="newConta.tipo"
                  :options="contasTiposLabel"
                  emit-value
                  map-options
                  id="tipo"
                  label="Tipo"
                  bottom-slots
                  :error="errorsContas.tipo ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsContas.tipo" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-12" v-if="newConta.tipo === 'banco-dourasoft'">
                <q-input
                  class="col-6"
                  v-model="newConta.password"
                  :hint="newConta.hasPassword ? 'Sua senha foi salva. Para alterar, digite a nova senha e clique em salvar.' : ''"
                  type="password"
                  label="Senha"
                  bottom-slots
                  :error="errorsContas.password ? true : false"
                >
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsContas.password" :key="error + i">{{error}}</span>
                  </template>
                </q-input>
              </div>
              <div class="q-mb-lg">
                <div class="block">
                  <q-toggle
                    v-model="newConta.ativo"
                    color="positive"
                    label="Ativo"
                  />
                </div>
                <div class="block" >
                  <q-toggle
                    v-model="newConta.padrao_sangria"
                    color="positive"
                    label="Conta padrão sangria"
                  />
                </div>
                <div class="block">
                  <q-toggle
                    v-model="newConta.padrao_reforco"
                    color="positive"
                    label="Conta padrão reforço"
                  />
                </div>
              </div>
              <div class="col-12 col-md-12">
                 <q-btn
                  no-wrap
                  v-ripple
                  label="Salvar"
                  no-caps
                  id="salvarConta"
                  class="q-ml-sm q-px-md bg-positive text-white absolute-bottom-right"
                  style="margin: 0 30px 20px 0"
                  clickable
                  type="submit"
                  :loading="salvando"
                  :disable="salvando"
                  @click="salvar()"
                >
                  <template v-slot:loading>
                    <q-spinner color="grey-10" size="1em" />
                  </template>
                </q-btn>
              </div>
              <div>
                <div class="absolute-bottom-right">
                  <q-btn
                    v-if="!newConta.isNew"
                    type="button"
                    label="Excluir"
                    flat
                    class="q-ml-sm q-px-md text-red"
                    style="margin: 0 10px 20px 0"
                    @click="excluir(newConta)"
                  />
                  <q-btn
                    no-wrap
                    v-ripple
                    label="Salvar"
                    no-caps
                    id="salvarConta"
                    class="q-ml-sm q-px-md bg-positive text-white "
                    style="margin: 0 30px 20px 0"
                    clickable
                    type="submit"
                    :loading="salvando"
                    @click="salvar()"
                  >
                    <template v-slot:loading>
                      <q-spinner color="grey-10" size="1em" />
                    </template>
                  </q-btn>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify } from "quasar";
export default {
  name: "modal-nova-conta",
  data() {
    return {
      salvando: false,
      operacao: [
        {
          value: "000",
          title: "000 - Sem Operação"
        },
        {
          value: "001",
          title: "001 – Conta Corrente - P.Física"
        },
        {
          value: "002",
          title: "002 – Conta Simples - P.Física"
        },
        {
          value: "003",
          title: "003 – Conta Corrente - P.Jurídica"
        },
        {
          value: "006",
          title: "006 – Entidades Públicas"
        },
        {
          value: "007",
          title: "007 – Dep. Instituições Financeiras"
        },
        {
          value: "013",
          title: "013 – Poupança"
        },
        {
          value: "022",
          title: "022 – Poupança - Pessoa Jurídica"
        },
        {
          value: "023",
          title: "023 – Conta CAIXA Fácil"
        },
        {
          value: "028",
          title: "028 – Poupança Crédito Imobiliário"
        },
        {
          value: "032",
          title: "032 – Conta Investimento - P.Física"
        },
        {
          value: "034",
          title: "034 – Conta Investimento - P.Jurídica"
        },
        {
          value: "043",
          title: "043 – Depósitos Lotéricos"
        }
      ]
    };
  },
  methods: {
    salvar() {
      let conta = JSON.parse(JSON.stringify(this.newConta));

      conta.operacao = !conta.operacao.value ? "000" :  conta.operacao.value;

      if (this.salvando) return;

      let data = {
        agencia: this.newConta.agencia,
        operacao: conta.operacao,
        conta: this.newConta.conta,
        tipo: this.newConta.tipo,
        titulo: this.newConta.titulo,
        ativo: this.newConta.ativo,
        padrao_sangria: this.newConta.padrao_sangria,
        padrao_reforco: this.newConta.padrao_reforco
      };

      if (this.newConta.tipo === 'banco-dourasoft') {
        data.password = this.newConta.password;
        delete this.newConta.password;
      }

      let url = this.newConta.isNew
        ? "/contas/add"
        : "/contas/edit/" + this.newConta.id;
      let sucecssMessage = this.newConta.isNew
        ? "Conta " + this.newConta.titulo + " adicionada com sucesso!"
        : "Conta " + this.newConta.titulo + " editada com sucesso!";

      this.salvando = true;

      axios
        .post(url, data)
        .then(res => {
          if (res.data.success) {
            Notify.create({
              message: sucecssMessage,
              color: "green",
              position: "top-right"
            });
            this.$store.commit("contas", []);
            this.$store.dispatch("contas", true);
            this.closeModal();
          } else {
            Notify.create({
              message: "Verifique os campos!",
              color: "orange",
              position: "top-right"
            });
            this.$store.commit("errorsContas", res.data.errors);
          }
          this.salvando = false;
        })
        .catch(() => {
          Notify.create({
            message:
              "Não foi possível cadastrar a nova conta, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.salvando = false;
        });
    },
    closeModal() {
      this.$store.commit("OpenDialogNovaConta", false);
      this.$store.commit("errorsContas", []);
      this.$store.commit("newConta", {
        agencia: "",
        operacao: "",
        conta: "",
        tipo: "",
        isNew: true,
        id: null,
        titulo: "",
        ativo: false,
        padrao_sangria: "",
        padrao_reforco: ""
      });
    },
    excluir(conta) {
      this.$store.dispatch("excluirConta", conta);
    }
  },
  computed: {
    OpenDialogNovaConta: {
      get() {
        return this.$store.getters.OpenDialogNovaConta;
      },
      set() {}
    },
    ...mapGetters([
      "contasOperacoes",
      "contasTipos",
      "newConta",
      "errorsContas"
    ]),
    contasTiposLabel() {
      const contasTipos = this.contasTipos;

      // Crias as labels através do tipo da conta
      const labels = contasTipos.map((tipo) => {
        const words = tipo.split('-');
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return {
          label: capitalizedWords.join(' '),
          value: tipo
        };
      });

      return labels;
    }
  },
  mounted() {}
};
</script>

<style lang="stylus" scoped>
.block{
  display:inline-block;
}
</style>
