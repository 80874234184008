<template>
  <div id="modalAdicionarRemoverEstoque">
    <q-dialog v-model="openDialogEstoque" persistent>
      <q-card style="width: 467px; max-width: 80vw;" v-bind:class="{'card-aberto': exibirTransferirParaFuncionario()}">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">{{ getTituloDialog() }}</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />

        <q-card-section class="row">
          <div
            class="q-pa-md col-12"
            v-bind:class="{'col-md-5': exibirTransferirParaFuncionario()}"
          >
            <!-- Informações -->
            <div class="text-black q-mb-lg" v-if="tipoDialogEstoque !== 'novo'">
              <p v-if="funcionario" class="q-mb-none" style="font-size: 1.2em;">
                {{ funcionario.nome }}
              </p>
              <p v-if="estoque.nome" class="q-mb-none">
                {{ estoque.nome }}
              </p>
              Controle
              <strong>{{estoque.tpcontrole}} {{estoque.extracao}} {{estoque.concurso}} {{estoque.evento}} {{estoque.vencimento | formatDate}}</strong>
              <br />
              <span v-if="tipo === 'empresa'">
                Quantidade Empresa
                <strong>{{estoque.qtd_empresa}}</strong>
                <br />Quantidade Funcionários
                <strong>{{estoque.qtd_funcionarios}}</strong>
              </span>
              <span v-else>
                Quantidade
                <strong>{{estoque.quantidade}}</strong>
              </span>
            </div>
            <!-- /Fim Informações -->

            <div class="q-col-gutter-md row">

              <!-- AutoComplete Produto -->
              <autocomplete
               id="produtoEstoque"
                title="Produto"
                :filter="filterFn"
                :carregando="carregando"
                :options="produtosEstoque"
                :formatDisplay="formatDisplay"
                :create="() => {}"
                @resultAutocomplete="resultEmitRecebe"
                :display="displayMontar"
                v-if="this.tipoDialogEstoque === 'novo'"
              />
              <!-- /Fim AutoComplete Produto -->

              <!-- Novo Item: Evento, extração, concurso -->
              <div
                class="col-12 col-md-12"
                v-if="exibirTpController()"
              >
                <q-input
                  @input="buscaAno"
                  aria-autocomplete="false"
                  v-model="tpcontroleqnt"
                  :mask="tipoIsEvento() ? '####' : '########'"
                  input-class="text-right"
                  :label="getLabelTpControler()"
                  ref="tpcontroleqntRef"
                  @keydown.enter="() => pulaProxCampo('tpControleOpcao')"
                />
              </div>
              <div
                class="col-12 col-md-12"
                v-if="exibirVencimento()">
                <q-input
                  v-model="vencimento"
                  input-class="text-right"
                  label="Vencimento"
                  @click="$refs.vencimentoRef.show()"
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                      <q-popup-proxy
                        ref="vencimentoRef"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date
                          v-model="vencimento"
                          mask="DD/MM/YYYY"
                          minimal
                          today-btn
                          :locale="langDate"
                          @input="() => pulaProxCampo('quantidade')"
                        />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div
                class="col-12 col-md-12"
                v-if="exibirTpController() && tipoIsEvento()"
              >
                <q-select
                  v-model="evento"
                  :option-label="value => value === null ? 'Item Vazio' : value.nome"
                  :option-value="value => value === null ? null : value"
                  :options="produtosEstoqueAno"
                  label="Evento"
                  bottom-slots
                  ref="eventoRef"
                  :error="errorsEstoque.produto_id ? true : false"
                  @input="() => pulaProxCampo('quantidade')"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                  <template v-slot:error>
                    <span v-for="(error, i) in errorsEstoque.produto_id" :key="error + i">{{error}}</span>
                  </template>
                </q-select>
              </div>
              <!-- /Fim Novo Item: Evento, extração, concurso -->

              <!-- Checkbox Transferir Funcionários -->
              <div class="col-12 col-md-12" v-if="exibirInputQuantidade()">
                <div class="row items-center no-wrap">
                  <q-input
                    v-model="quantidade"
                    mask="#####"
                    input-class="text-right"
                    class="full-width"
                    label="Quantidade"
                    ref="quantidadeRef"
                    @keydown.enter="adicionaRemove"
                  />
                  <q-btn
                    round
                    color="primary"
                    size="sm"
                    icon="remove"
                    class="q-mx-sm"
                    @click="addQuantidade(-1)"
                  />
                  <q-btn
                    round
                    color="primary"
                    size="sm"
                    icon="add"
                    @click="addQuantidade(+1)"
                  />
                </div>
              </div>
              <div class="col-12 col-md-12" style="padding-left: 7px" v-if="exibirCheckboxTransferirParaFuncionario()">
                <!-- <q-checkbox
                  v-model="transferirParaFuncionario"
                  label="Adicionar para funcionário"
                  color="green"
                  @input="val => !val ? pulaProxCampo('quantidade') : null"
                /> -->
                <q-toggle
                  v-model="transferirParaFuncionario"
                  @input="val => !val ? pulaProxCampo('quantidade') : null"
                  color="green"
                  :label="!transferirParaFuncionario ? 'O estoque está indo para a empresa' : 'O estoque está indo para o funcionário'"
                />
              </div>
              <!-- /Fim Checkbox Transferir Funcionários -->

            </div>
          </div>
          <!-- Tabela Funcionários -->
          <div
            class="q-pa-md col-12 col-md-7"
            v-if="exibirTransferirParaFuncionario()"
          >
            <q-scroll-area style="height: 300px;">
              <q-markup-table dense class="no-shadow">
                <thead>
                  <tr>
                    <th class="text-left">Funcionário</th>
                    <th class="text-right small-column">Quantidade</th>
                    <th class="text-right small-column">R$</th>
                  </tr>
                </thead>
                <tbody v-if="funcionariosEstoque.length">
                  <tr
                    v-for="(funcionario, index) in outrosFuncionarios()"
                    :key="index"
                    :id="`funcionario${index}`"
                  >
                    <td>{{ funcionario.nome }}</td>
                    <td class="text-right">
                      <q-input
                        :autofocus="index === 0"
                        type="text"
                        v-model.number="funcionario.quantidade"
                        dense
                        input-class="text-right"
                        :ref="'funcionarioQuantidadeRef' + index"
                        @keyup.enter="() => pulaProxCampo('quantidadeFuncionario', { index })"
                      />
                    </td>
                    <td class="text-right">{{ formataValorParaUsuario(calculaValor(funcionario.quantidade)) }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td class="text-right"><strong>{{ calculaQuantidadeTotal() }}</strong></td>
                    <td class="text-right"><strong>{{ formataValorParaUsuario(calculaValor(calculaQuantidadeTotal())) }}</strong></td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" colspan="3">Nenhum funcionário encontrado</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-scroll-area>
            <!-- /Fim Tabela Funcionários -->

          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary row justify-between">
          <div style="margin-bottom: 10px; margin-left: 22px;" v-if="!exibirTransferirParaFuncionario()">
            <q-btn
              label="+5"
              @click="addQuantidade(5)"
              color="primary"
              class="q-mr-sm"
            />

            <q-btn
              label="+10"
              @click="addQuantidade(10)"
              color="primary"
              class="q-mr-sm"
            />

            <q-btn
              label="+50"
              @click="addQuantidade(50)"
              color="primary"
              class="q-mr-sm"
            />

            <q-btn
              label="+100"
              @click="addQuantidade(100)"
              color="primary"
              class="q-mr-sm"
            />
          </div>
          <div v-else></div>

          <q-btn
            flat
            :label="getTextoBotao()"
            clickable
            @click="adicionaRemove"
            :loading="loadingSalvarEstoque"
            class="text-white"
            :class="{'bg-positive': tipoDialogEstoque !== 'remover', 'bg-negative': tipoDialogEstoque === 'remover'}"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

        <q-separator />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const moment = require("moment");
export default {
  name: "modal-adicionar-remover-estoque",
  props: ["tipoDialogEstoque", "tipo"],
  data() {
    return {
      quantidade: null,
      funcionario: null,
      errors: [],
      produto: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        masked: false,
        precision: 2,
      },
      tpcontrole: "",
      tpcontroleqnt: null,
      vencimento: null,
      transferirParaFuncionario: false,
      evento: {},
      // Funções do autocomplete
      carregando: false,
    };
  },
  watch: {
    estoque(value) {
      if (this.tipo === "operador") {
        this.returnFuncionario();
      }
      if (value.extracao) {
        this.vencimento = moment(value.vencimento).format("DD/MM/YYYY");
        this.tpcontroleqnt = value.extracao;
      }
      if (value.concurso) {
        this.tpcontroleqnt = value.concurso;
      }
      if (value.evento_id) {
        this.tpcontroleqnt = value.ano;
        this.returnEvento(value.evento_id);
      }
    },
    funcionariosEstoque(value) {
      if (this.tipo === "operador") {
        this.returnFuncionario();
      }
    },
  },
  methods: {
    // Exibição
    exibirCheckboxTransferirParaFuncionario() {
      // Caso seja a opção transferir e haja um funcionário ou
      // o dialog seja de novo estoque
      return ((this.tipoDialogEstoque === "transferir" && this.funcionario) || this.tipoDialogEstoque === "novo");
    },
    exibirInputQuantidade() {
      // Caso a opção de transferir para funcionário não esteja marcada ou
      // seja um dialog diferente de transferir que tenha funcionário
      return (!this.transferirParaFuncionario && (this.funcionario || this.tipoDialogEstoque !== "transferir"));
    },
    exibirTransferirParaFuncionario() {
      // Caso seja um dialog de transferir e não haja funcionário ou
      // a opção transferir para funcionário esteja marcado
      return ((this.tipoDialogEstoque === "transferir" && !this.funcionario) || this.transferirParaFuncionario);
    },
    exibirTpController() {
      return (this.produto &&this.produto.tpcontrole && this.produto.tpcontrole !== "Nenhum" && Object.keys(this.estoque).length === 0);
    },
    exibirVencimento() {
      return (this.exibirTpController() && this.tipoIsExtracao());
    },
    // Tipos
    tipoIsExtracao() {
      return (this.produto && this.produto.tpcontrole === "Extração" || this.estoque.extracao);
    },
    tipoIsConcurso() {
      return  (this.produto && this.produto.tpcontrole === "Concurso" || this.estoque.concurso);
    },
    tipoIsEvento() {
      return (this.produto && this.produto.tpcontrole === "Evento" || this.estoque.evento_id);
    },
    //Úteis
    getTituloDialog() {
      let titulo = "Incluir estoque de produtos";

      if (this.tipoDialogEstoque === "remover") {
        titulo = "Remover estoque de produtos";
      } else if(this.tipoDialogEstoque === "transferir") {
        titulo = "Transferir estoque de produtos";
      }

      return titulo;
    },
    getTextoBotao() {
      let titulo = "Adicionar";

      if (this.tipoDialogEstoque === "remover") {
        titulo = "Remover";
      } else if(this.tipoDialogEstoque === "transferir") {
        titulo = "Transferir";
      }

      return titulo;
    },
    getLabelTpControler() {
      let label = "Extração";

      if (this.tipoIsEvento()) {
        label = "Ano do Evento";
      } else if(this.tipoIsConcurso()) {
        label = "Concurso";
      }

      return label;
    },
    // Autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (!val) return abort();
        this.carregando = true;
        this.$store.dispatch("produtosEstoque", {
          tipo: "P",
          nome: val,
          ativo: 1
        })
          .then(res => {
            this.carregando = false;
            this.produto = {};
          });
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    displayMontar(value) {
      return !value ? value : value.nome;
    },
    resultEmitRecebe(result) {
      this.produto = result;

      if (!this.produto || !this.produto.id) return;

      this.$q.notify({
        message: this.produto.nome + " foi selecionado.",
        color: "green",
        position: "top-right"
      });

      this.exibirTpController() ? this.pulaProxCampo("tpcontrole") : this.pulaProxCampo("quantidade");
    },
    // Todas as outras funções
    outrosFuncionarios() {
      return this.funcionariosEstoque.filter(funcionario => funcionario.id != this.estoque.funcionario_id);
    },
    closeModal() {
      this.quantidade = null;
      this.funcionario = null;
      this.errors = [];
      this.tpcontrole = "";
      this.tpcontroleqnt = null;
      this.vencimento = null;
      this.transferirParaFuncionario = false;
      this.evento = {};
      this.$store.commit("estoque", {});
      this.$store.commit("errorsEstoque", []);
      this.$store.commit("openDialogEstoque", false);
    },
    adicionaRemove() {
      if (
        (this.exibirInputQuantidade() && this.quantidade <= 0) ||
        (this.exibirTransferirParaFuncionario() && this.calculaQuantidadeTotal() <= 0)
      ) {
        this.$q.notify({
          message: "Quantidade não pode ser Zero ou Vazia!",
          position: "top-right",
          color: "orange",
        });
        return;
      }

      const produto_id = this.tipoDialogEstoque === "novo" ? this.produto.id : this.estoque.produto_id;
      const tpcontrole = this.tipoDialogEstoque === "novo" ? this.produto.tpcontrole : this.estoque.tpcontrole;
      this.$store
        .dispatch("salvaEstoque", {
          tipoDialogEstoque: this.tipoDialogEstoque,
          qnt: this.quantidade,
          id: produto_id,
          cadastros: this.formataFuncionarios(),
          funcionario: this.funcionario ? this.funcionario.id : null,
          cadastro_origem_id: this.tipoDialogEstoque === "transferir" && this.funcionario ? this.funcionario.id : null,
          cadastro_destino_id: null,
          extracao:
            this.tipoIsExtracao()
              ? parseInt(this.estoque.produto_id ? this.estoque.extracao : this.tpcontroleqnt)
              : null,
          concurso:
            this.tipoIsConcurso()
              ? parseInt(this.estoque.produto_id ? this.estoque.concurso : this.tpcontroleqnt)
              : null,
          evento_id: this.evento.id,
          vencimento: this.vencimento,
          tpcontrole: tpcontrole,
          tipo: this.tipo
        })
        .then(res => {
          if (res.data.success) {
            this.quantidade = null;
            this.funcionario = null;
            this.produto = {};
            this.errors = [];
            this.tpcontrole = "";
            this.tpcontroleqnt = null;
            this.vencimento = null;
            this.transferirParaFuncionario = false;
            this.evento = {};
            this.$store.commit("errorsEstoque", []);
          }
        });
    },
    formataFuncionarios() {
      const cadastros = [];

      this.funcionariosEstoque.forEach(cadastro => {
        if (parseInt(cadastro.quantidade) > 0) {
          cadastros.push({
            id: cadastro.id,
            quantidade: cadastro.quantidade,
          });
        }
      });

      return cadastros;
    },
    formataValorParaUsuario(valor) {
      return "R$ " + valor.toLocaleString("pt-BR", { minimumFractionDigits: 2, });
    },
    calculaValor(quantidade) {
      if (!this.estoque) return 0;

      let vlrvenda = this.estoque.vlrvenda;

      if (this.tipoDialogEstoque === "novo") {
        vlrvenda = this.produto ? this.produto.vlrvenda : 0;
      }

      if (!vlrvenda) {
        vlrvenda = 0;
      }

      return parseFloat(vlrvenda) * parseInt(quantidade);
    },
    calculaQuantidadeTotal() {
      let total = 0;

      this.funcionariosEstoque.forEach(funcionario => {
        total += parseInt(funcionario.quantidade);
      });

      return total;
    },
    buscaAno() {
      if (!this.tipoIsEvento()) return;
      if (this.tpcontroleqnt <= 1990) return;
      this.$store.dispatch("produtosEstoque", {
        tipo: "P",
        ano: this.tpcontroleqnt,
        ativo: 1
      });
    },
    returnFuncionario() {
      let id = this.estoque.funcionario_id
        ? this.estoque.funcionario_id
        : this.estoque.id;
      for (let i in this.funcionariosEstoque) {
        if (this.funcionariosEstoque[i].id === id) {
          this.funcionario = this.funcionariosEstoque[i];
        }
      }
    },
    returnEvento(id) {
      this.$store
        .dispatch("produtosEstoque", {
          tipo: "P",
          ano: this.tpcontroleqnt,
          ativo: 1
        })
        .then(res => {
          if (res.data.success) {
            for (let i in res.data.eventos) {
              if (res.data.eventos[i].id === id) {
                this.evento = res.data.eventos[i];
              }
            }
          }
        });
    },
    addQuantidade(valor) {
      let quantidade = parseInt(this.quantidade);

      if (isNaN(quantidade)) {
        quantidade = 0;
      }

      quantidade += valor;

      if (quantidade < 0) return;

      this.quantidade = quantidade;
    },
    pulaProxCampo(campoAtual, parametros = {}) {
      switch(campoAtual) {

        case "quantidade":
          this.$nextTick(() => {
            if (this.$refs.quantidadeRef) {
              this.$refs.quantidadeRef.select();
            }
          });
          break;

        case "tpcontrole":
          this.$nextTick(() => {
            this.$refs.tpcontroleqntRef.select();
          });
          break;

        case "tpControleOpcao":

          if (this.tipoIsEvento()) {
            this.$refs.eventoRef.focus();
            this.$refs.eventoRef.showPopup();
            break;
          }

          if (this.tipoIsExtracao()) {
            this.$refs.vencimentoRef.show();
            break;
          }

          if (this.tipoIsConcurso()) {
            this.$refs.quantidadeRef.select();
            break;
          }

          break;

        case "quantidadeFuncionario":
          if (this.funcionariosEstoque.length == 0) return;

          if (parametros.index === this.funcionariosEstoque.length - 1) {
            this.adicionaRemove();
            break;
          }

          if (!this.funcionariosEstoque[parametros.index + 1]) return;
          this.$refs["funcionarioQuantidadeRef" + (parametros.index + 1)][0].select();

          break;

      }
    },
  },
  computed: {
    ...mapGetters([
      "produtosEstoque",
      "loadingSalvarEstoque",
      "funcionariosEstoque",
      "estoque",
      "produtosEstoqueAno",
      "errorsEstoque",
      "listarFuncionarios"
    ]),
    openDialogEstoque: {
      get() {
        return this.$store.getters.openDialogEstoque;
      },
      set() {}
    }
  }
};
</script>

<style lang="stylus" scoped>
  .card-aberto
    width: 850px !important

  .q-table

    thead th
      font-weight: bold

    .small-column
      width: 100px;

</style>
