<template>
  <q-page id="conciliacao">

    <div v-if="loadingConciliacao" class="text-center q-mt-xl">
      <q-spinner
        color="primary"
        size="4em"
      />
    </div>

    <div v-else :class="`q-px-${ $q.screen.gt.md ? 'xl' : 'md' }`" style="padding-bottom: 128px;">

      <div class="q-pa-md q-pb-none">
        <div class="text-center">
            <div class="q-mb-md">
              <h1 class="q-my-none inline-block">Conciliação Bancária de {{ meta.dtini }} - {{ meta.dtfim }}</h1>

              <q-btn
                v-if="hasVideo"
                flat
                dense
                color="primary"
                class="q-ml-md float-right"
                icon="ondemand_video"
                @click="openModalIntroducao()"
              >
                <template v-slot:default>
                  <q-tooltip>Introdução a Conciliação</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                flat
                dense
                color="red"
                class="q-ml-md float-right"
                icon="delete"
                @click="deleteConciliacao"
              >
                <template v-slot:default>
                  <q-tooltip>Desfazer Conciliação</q-tooltip>
                </template>
              </q-btn>
            </div>
            <p class="text-weight-bold q-mb-none" style="font-size: 1rem;">
              {{ contaExtrato.titulo }} - Arquivo {{ arquivo.nome }} contendo {{ meta.countRegistros }} registros
            </p>
        </div>
      </div>

      <div class="q-mx-md q-mb-lg text-center">
        <q-btn-group unelevated class="justify-center" style="flex-wrap: wrap;">
          <q-btn
            dense
            color="grey"
            label="Todos"
            class="q-px-xs full-height"
            :outline="Boolean(filtros.dtextrato)"
            @click="changeDtExtrato()"
          />

          <q-btn
            v-for="(day, index) in dias_conciliados"
            :key="index"
            :color="getCorRange(day)"
            @click="changeDtExtrato(day.dtmovimento)"
            class="q-px-xs"
            dense
          >
            {{ getDayLabel(day) }}
            <q-tooltip>
              {{ getDayInfo(day, true) }} <br/>
              {{ getDayInfo(day, false) }}
            </q-tooltip>
          </q-btn>
        </q-btn-group>
      </div>

      <div class="row q-col-gutter-md">
        <div class="col-12 col-md-6">
          <q-table
            :dense="$q.screen.lt.md"
            hide-bottom
            row-key="id"
            selection="multiple"
            :columns="columnsExtratos"
            :data="extratosFiltrado"
            :pagination.sync="pagination"
            :loading="loadingExtratos"
            :selected.sync="checkedExtratos"
          >
            <template v-slot:top>
              <div class="full-width flex justify-between items-center q-mb-md" style="height: 40px;">
                <div style="flex: 1;">
                  <b>Extrato Bancário</b>
                </div>

                <div style="flex: 1;" :class="{ 'q-mx-md': true, 'text-center': $q.screen.lt.lg }">
                  <q-btn-group rounded flat no-wrap no-caps>
                    <q-btn
                      rounded
                      clickable
                      icon="chevron_left"
                      class="white text-blue-7"
                      @click="changeFilterDtExtrato('prev')"
                    />

                    <q-btn
                      class="white text-blue-7"
                      rounded
                      :label="filtros.dtiniExtrato"
                    >
                      <template v-slot:default>
                        <q-popup-proxy
                          ref="inputDtiniExtrato"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            minimal
                            today-btn
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            :options="filterDtExtratoOptions"
                            v-model="filtros.dtiniExtrato"
                            @input="selecionarDtExtrato('inputDtiniExtrato')"
                          />
                        </q-popup-proxy>
                      </template>
                    </q-btn>

                    <q-btn
                      rounded
                      class="white text-blue-7"
                      :label="filtros.dtfimExtrato"
                    >
                      <template v-slot:default>
                        <q-popup-proxy
                          ref="inputDtfimExtrato"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            minimal
                            today-btn
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            :options="filterDtExtratoOptions"
                            v-model="filtros.dtfimExtrato"
                            @input="selecionarDtExtrato('inputDtfimExtrato')"
                          />
                        </q-popup-proxy>
                      </template>
                    </q-btn>

                    <q-btn
                      rounded
                      clickable
                      icon="chevron_right"
                      class="white text-blue-7"
                      @click="changeFilterDtExtrato('next')"
                    />
                  </q-btn-group>
                </div>

                <div style="flex: 1 1 100px;">
                  <q-input
                    v-model="filtros.buscaNomenclaturaExtrato"
                    debounce="500"
                    label="Busca"
                    @keyup.enter="searchNomenclaturaExtrato"
                    dense
                  >
                    <template v-slot:append>
                      <q-icon
                        v-if="filtros.buscaNomenclaturaExtrato !== ''"
                        name="close"
                        class="cursor-pointer"
                        @click="filtros.buscaNomenclaturaExtrato = ''"
                      />
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </div>

                <div style="flex: 1;" class="text-right">
                  <q-btn
                    flat
                    dense
                    no-wrap
                    v-ripple
                    no-caps
                    color="primary"
                    :label="filtros.tipoExtrato.label"
                  >
                    <q-menu anchor="top right" self="top right">
                      <q-list style="min-width: 100px">
                        <q-item
                          clickable
                          v-close-popup
                          v-for="option in optionsTipoExtrato"
                          :key="option.value"
                          :class="{'active_status': filtros.tipoExtrato.value === option.value}"
                          :disable="filtros.tipoExtrato.value === option.value"
                        >
                          <q-item-section @click="changeTipoExtrato(option)" >{{ option.label }}</q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>

              </div>
            </template>

            <template v-slot:body="props">
              <q-tr
                :props="props"
                @click="event => selecionarExtrato(props.row, event)"
                @dblclick.prevent="selectAllExtratos"
                :class="{
                  'extrato-selecionado': getIsExtratoSelecionado(props.row.id),
                  'lancamento-conciliado': getIsExtratoConciliado(props.row.id),
                }"
              >
                <q-td auto-width>
                  <q-checkbox
                    v-model="props.selected"
                    color="secondary"
                  />
                </q-td>

                <q-td key="dtmovimento" class="text-center">
                  {{ new Date(props.row.dtmovimento) | formatDate }}
                </q-td>

                <q-td key="descricao" class="text-left">
                  {{ props.row.descricao && props.row.descricao.length > 30 ? props.row.descricao.substr(0, 30) + '...' : props.row.descricao }}
                  <q-tooltip>
                    <span v-if="props.row.descricao && props.row.descricao.length > 30">{{ props.row.descricao }}</span>
                    Arquivo {{ props.row.arquivo_id }}
                  </q-tooltip>
                </q-td>

                <q-td key="valor" class="text-right">
                  R$ {{ props.row.valor | currency }}
                  <q-btn
                    flat
                    size="sm"
                    icon="search"
                    @click.stop="buscarValorExtrato(props.row.valor)"
                  />
                </q-td>

                <q-td
                  key="conciliado"
                  class="text-right expandir"
                >
                  ({{ parseInt(props.row.quantidade_conciliada || 0) }})

                  <span :class="{'text-green': props.row.valor - props.row.total_conciliada == 0}">
                    R$ {{ props.row.total_conciliada | currency }}
                  </span>

                  <br/>

                  <span v-if="props.row.valor - props.row.total_conciliada != 0" class="text-red">
                    R$ {{ props.row.valor - props.row.total_conciliada | currency }}
                  </span>
                </q-td>

              </q-tr>
            </template>
          </q-table>
        </div>

        <div class="col-12 col-md-6">
          <q-card>
            <q-table
              :dense="$q.screen.lt.md"
              hide-bottom
              selection="multiple"
              :data="lancamentosSistemaFiltrado"
              :loading="loadingLancamentosSistema"
              :columns="columnsLancamentosSistema"
              :selected.sync="selectedLancamentosSistema"
              :pagination.sync="paginationLancamentosSistema"
              :row-key="row => `${row.id}${row.descricao}${row.dtmovimento}${row.liquidacao}`"
            >
              <template v-slot:top>
                <div class="full-width flex items-center justify-between q-mb-md">

                  <div style="flex: 1;" :class="{ 'text-center': $q.screen.lt.lg }">
                    <b>Lançamentos Sistema</b>
                  </div>

                  <div style="flex: 1;" :class="{ 'q-mx-md': true, 'text-center': $q.screen.lt.lg }">
                    <q-btn-group rounded flat no-wrap no-caps>
                      <q-btn
                        rounded
                        clickable
                        icon="chevron_left"
                        class="white text-blue-7"
                        @click="changeFilterPeriodo('prev')"
                      />

                      <q-btn
                        class="white text-blue-7"
                        rounded
                        :label="filtros.dtiniSistema"
                      >
                        <template v-slot:default>
                          <q-popup-proxy
                            ref="inputDtiniSistema"
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              minimal
                              today-btn
                              mask="DD/MM/YYYY"
                              :locale="langDate"
                              v-model="filtros.dtiniSistema"
                              @input="selecionarDtsistema('inputDtiniSistema')"
                            />
                          </q-popup-proxy>
                        </template>
                      </q-btn>

                      <q-btn
                        rounded
                        class="white text-blue-7"
                        :label="filtros.dtfimSistema"
                      >
                        <template v-slot:default>
                          <q-popup-proxy
                            ref="inputDtfimSistema"
                            transition-show="scale"
                            transition-hide="scale"
                          >
                            <q-date
                              minimal
                              today-btn
                              mask="DD/MM/YYYY"
                              :locale="langDate"
                              v-model="filtros.dtfimSistema"
                              @input="selecionarDtsistema('inputDtfimSistema')"
                            />
                          </q-popup-proxy>
                        </template>
                      </q-btn>

                      <q-btn
                        rounded
                        clickable
                        icon="chevron_right"
                        class="white text-blue-7"
                        @click="changeFilterPeriodo('next')"
                      />
                    </q-btn-group>
                  </div>

                  <div style="flex: 1 1 100px;" :class="`${ $q.screen.lt.lg ? 'q-pt-none' : '' }`">
                    <q-input
                      v-model="filtros.buscaLancamentosSistema"
                      debounce="500"
                      label="Busca"
                      dense
                    >
                      <template v-slot:append>
                        <q-icon
                          v-if="filtros.buscaLancamentosSistema !== ''"
                          name="close"
                          class="cursor-pointer"
                          @click="filtros.buscaLancamentosSistema = ''"
                        />
                        <q-icon name="search" />
                      </template>
                    </q-input>
                  </div>

                  <div style="flex: 1;" class="text-right">
                    <q-btn
                      flat
                      dense
                      no-wrap
                      v-ripple
                      no-caps
                      color="primary"
                      :label="filtros.tipoLancamento.label"
                    >
                      <q-menu anchor="top right" self="top right">
                        <q-list style="min-width: 100px">
                          <q-item
                            clickable
                            v-close-popup
                            v-for="option in optionsTipoExtrato"
                            :key="option.value"
                            :class="{'active_status': filtros.tipoLancamento.value === option.value}"
                            :disable="filtros.tipoLancamento.value === option.value"
                            :style="option.value === 'ignorados' ? 'display: none' : ''"
                          >
                            <q-item-section @click="changeTipoLancamento(option)">{{ option.label }}</q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>

                </div>
              </template>
              <template v-slot:body="props">
                <q-tr
                  :props="props"
                  :class="{
                    'lancamento-conciliado': selectedExtratos.some(extrato => extrato.lancamentosSistemaConciliados.includes(props.row.id))
                  }"
                >
                  <q-td auto-width>
                    <q-checkbox
                      color="secondary"
                      v-model="props.selected"
                      v-if="props.row.tipo !== 'Saldo'"
                    />
                  </q-td>

                  <q-td key="liquidacao" class="text-center">
                    <div>
                      <q-badge color="green" v-if="props.row.conciliado">Conciliado</q-badge>
                    </div>

                    <span v-if="props.row.dtmovimento != null">
                      {{ new Date(props.row.dtmovimento) | formatDate }}
                    </span>

                    <span v-else>
                      {{ new Date(props.row.liquidacao) | formatDate }}
                    </span>

                    <q-tooltip v-if="props.row.liquidacao  != props.row.dtmovimento" >
                      Liquidação:  {{ new Date(props.row.liquidacao) | formatDate }}
                    </q-tooltip>
                  </q-td>

                  <q-td key="descricao" class="break-spaces">
                    <p v-if="props.row.favorecido" class="q-mb-none">
                      {{ String(props.row.favorecido).length > 30 ? props.row.favorecido.substr(0, 30) + '...' : props.row.favorecido }}
                    </p>

                    {{ String(props.row.descricao).length > 30 ? props.row.descricao.substr(0, 30) + '...' : props.row.descricao }}

                    <q-tooltip v-if="String(props.row.descricao).length > 30" >
                      {{ props.row.descricao }}
                    </q-tooltip>

                    <q-tooltip v-if="props.row.favorecido && props.row.favorecido.length > 30" >
                      {{ props.row.favorecido }}
                    </q-tooltip>
                  </q-td>

                  <q-td key="valor_liquidado" class="text-right">
                    R$ {{ props.row.valor_liquidado | currency }}
                  </q-td>

                  <q-td key="expandir" class="text-right" style="width: 40px; padding: 8px;">
                    <q-btn
                      v-if="props.row.agrupada"
                      flat
                      color="grey"
                      style="width: 36px;"
                      class="botao-expandir"
                      :loading="props.row.loadingDetalhamento"
                      :icon="props.expand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                      @click="exibirLancamentos(props)"
                    />
                  </q-td>

                </q-tr>
                <q-tr
                  :props="props"
                  :key="lancamento.id"
                  v-show="props.expand"
                  v-for="lancamento in props.row.lancamentos"
                  :class="{
                    'lancamento-conciliado': extratoSelecionado && extratoSelecionado.lancamentosSistemaConciliados.includes(lancamento.id)
                  }"
                >
                  <q-td auto-width></q-td>

                  <q-td key="liquidacao" class="text-center">
                    <div>
                      <q-badge color="green" v-if="lancamento.conciliado">Conciliado</q-badge>
                    </div>

                    <span v-if="props.row.dtmovimento != null">
                        {{ new Date(props.row.dtmovimento) | formatDate }}
                    </span>

                    <span v-else>
                      {{ new Date(props.row.liquidacao) | formatDate }}
                    </span>

                    <q-tooltip v-if="props.row.liquidacao  != props.row.dtmovimento" >
                      Liquidação:  {{ new Date(props.row.liquidacao) | formatDate }}
                    </q-tooltip>
                  </q-td>

                  <q-td key="descricao" class="break-spaces" style="padding-left:48px;">
                    <p v-if="lancamento.cadastro" class="q-mb-none">
                      {{ lancamento.cadastro.nome.length > 30 ? lancamento.cadastro.nome.substr(0, 30) + '...' : lancamento.cadastro.nome }}
                    </p>

                    {{ String(lancamento.descricao).length > 30 ? lancamento.descricao.substr(0, 30) + '...' : lancamento.descricao }}

                    <q-tooltip v-if="String(lancamento.descricao).length > 30">
                      {{ lancamento.descricao }}
                    </q-tooltip>

                    <q-tooltip v-if="lancamento.cadastro && lancamento.cadastro.nome.length > 30">
                      {{ lancamento.cadastro.nome }}
                    </q-tooltip>
                  </q-td>

                  <q-td key="valor_liquidado" class="text-right">
                    R$ {{ lancamento.valor_liquidado | currency}}
                  </q-td>

                  <q-td key="expandir"></q-td>

                </q-tr>
              </template>
            </q-table>
          </q-card>

        </div>
      </div>

    </div>

  <q-card class="card-info fixed-bottom">
    <q-card-section :class="`q-pt-${cardInformacoesExpanded ? 'sm' : 'none'}`" style="position: initial;">
      <div class="absolute-right">
        <q-btn
          flat
          round
          dense
          color="grey-7"
          :icon="cardInformacoesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
          @click="cardInformacoesExpanded = !cardInformacoesExpanded"
        />
      </div>

      <q-slide-transition @show="updateClassName" @hide="updateClassName">
        <div v-show="cardInformacoesExpanded" ref="cardInfoTotal" class="q-pr-xl">

          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6">
              <div class="q-mb-sm">
                 <strong>Extrato Bancário</strong>
              </div>

              <div class="flex">
                <div style="flex: 1;" class="q-mr-lg">
                  <div class="flex justify-between">
                    <strong>Total</strong>
                    <span>R$ {{ valorTotalExtrato | currency }}</span>
                  </div>
                  <div class="flex justify-between">
                    <strong>Selecionado</strong>
                    <span>R$ {{ valorTotalExtratoSelecionado | currency }}</span>
                  </div>
                </div>

                <div style="width: 256px;">
                  <q-btn
                    icon="search"
                    class="q-mr-sm"
                    color="secondary"
                    :disable="disableBotaoBuscarLancamentoExtrato"
                    @click="buscarLancamentoExtrato()"
                  >
                    <q-tooltip>
                      Buscar um lançamento
                    </q-tooltip>
                  </q-btn>

                  <q-btn
                    icon="add"
                    color="primary"
                    class="q-mr-sm"
                    :disable="disableBotaoIncluirLancamentoExtrato"
                    @click="incluirLancamentoExtrato()"
                  >
                    <q-tooltip>
                      Incluir Lançamento
                    </q-tooltip>
                  </q-btn>

                  <q-btn
                    color="green"
                    class="q-mr-sm"
                    icon="fa fa-bolt"
                    :disable="disableBotaoLancarDiferencaExtrato"
                    @click="lancarDiferencaExtrato()"
                  >
                    <q-tooltip>
                      Lançar Diferença
                    </q-tooltip>
                  </q-btn>

                  <q-btn
                    :disable="disableBotaoIgnorarReativarExtrato"
                    :color="tipoBotaoIgnorarReativarExtrato === 'reativar' ? 'green' : 'red'"
                    :icon="tipoBotaoIgnorarReativarExtrato === 'reativar' ? 'undo' : 'highlight_off'"
                    @click="ignorarReativarExtrato()"
                  >
                    <q-tooltip>
                      {{ tipoBotaoIgnorarReativarExtrato === 'reativar' ? 'Reativar' : 'Ignorar' }} Lançamento
                    </q-tooltip>
                  </q-btn>

                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="q-mb-sm">
                 <strong>Lançamentos Sistema</strong>
              </div>

              <div class="flex">
                <div style="flex: 1;" class="q-mr-lg">
                  <div class="flex justify-between">
                    <strong>Total</strong>
                    <span>R$ {{ valorTotalLancamentosSistema | currency }}</span>
                  </div>
                  <div class="flex justify-between">
                    <strong>Selecionado</strong>
                    <span>R$ {{ valorTotalLancamentosSistemaSelecionado | currency }}</span>
                  </div>
                </div>

                <div style="flex: 1;">
                  <div>
                    <strong>Diferença</strong>
                    R$ {{ valorDiferencaLancamentosTotal | currency }}
                  </div>
                  <div>
                    <strong>Diferença</strong>
                    R$ {{ valorDiferencaLancamentosSelecionados | currency }}
                  </div>
                </div>

                <div style="width: 192px;">
                  <q-btn
                    icon="check"
                    class="q-mr-sm"
                    color="positive"
                    :loading="loadingConciliar"
                    :disable="disableBotaoConciliarLancamentosSistema"
                    @click="conciliar()"
                  >
                    <q-tooltip>
                      Conciliar
                    </q-tooltip>
                  </q-btn>

                  <q-btn
                    icon="clear"
                    class="q-mr-sm"
                    color="negative"
                    :loading="loadingDesconciliar"
                    :disable="disableBotaoDesconciliarLancamentosSistema"
                    @click="desconciliar()"
                  >
                    <q-tooltip>
                      Desconciliar
                    </q-tooltip>
                  </q-btn>

                  <q-btn
                    icon="edit"
                    class="q-mr-sm"
                    color="warning"
                    :disable="disableBotaoEditarLancamentosSistema"
                    @click="editarLancamentoSistema()"
                  >
                    <q-tooltip>
                      Editar
                    </q-tooltip>
                  </q-btn>
                </div>
              </div>

            </div>
          </div>

        </div>
      </q-slide-transition>
    </q-card-section>
  </q-card>

    <modal-buscar-contas :data="dataBuscarContas" />
    <modal-editar-extrato />
    <modal-incluir-lancamento @clearExtratos="selectedExtratos = []" :contaExtrato="this.contaExtrato" />
    <modal-lancar-diferenca />
  </q-page>
</template>

<script>

import moment from 'moment';
import axios from '@/axios-auth';
import { bus } from '@/main';
import { mapGetters } from 'vuex';
import { Loading, QSlideTransition } from 'quasar';

export default {
  name:'conciliacao',
  meta: {
    title: 'DSB | Conciliação',
  },
  components: { QSlideTransition },
  data(){
    return {
      // Gerais
      dias_conciliados: [],
      contaExtrato: {},
      arquivo: {},
      filtros: {
        arquivo_id: null,
        conta_id: null,
        dtiniSistema: null,
        dtfimSistema: null,
        dtiniExtrato: null,
        dtfimExtrato: null,
        buscaLancamentosSistema: '',
        buscaNomenclaturaExtrato: '',
        tipoExtrato: {
          label: 'Todos',
          value: '',
        },
        tipoLancamento: {
          label: 'Todos',
          value: '',
        },
      },
      meta: {
        countRegistros: null,
        dtini: null,
        dtfim: null,
      },
      // Outros
      loadingConciliar: false,
      loadingDesconciliar: false,
      loadingConciliacao: false,
      cardInformacoesExpanded: true,
      // Extrato
      selectedExtratos: [],
      loadingExtratoSelecionado: null,
      loadingExtratos: false,
      extratos: [],
      checkedExtratos: [],
      allSelected: false,
      columnsExtratos: [
        {
          name: 'dtmovimento',
          label: 'Data',
          align: 'center',
          field: row => row.dtmovimento,
          sortable: true,
        },
        {
          name: 'descricao',
          required: true,
          align: 'center',
          label: 'Descrição',
          field: row => row.descricao,
          sortable: true,
        },
        {
          name: 'valor',
          required: true,
          align: 'center',
          label: 'Valor',
          field: row => parseFloat(row.valor),
          sortable: true,
        },
        {
          name: 'conciliado',
          required: true,
          align: 'center',
          label: 'Conciliado',
          field: row => row.conciliado,
          sortable: true,
        },
      ],
      pagination: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 0
      },
      optionsTipoExtrato: [
        {
          label: 'Todos',
          value: '',
        },
        {
          label: 'Conciliados',
          value: 'conciliados',
        },
        {
          label: 'Não Conciliados',
          value: 'nao_conciliados',
        },
        {
          label: 'Ignorados',
          value: 'ignorados',
        },
      ],
      // Lançamentos do Sistema
      loadingLancamentosSistema: false,
      lancamentosSistema: [],
      columnsLancamentosSistema: [
        {
          name: 'liquidacao',
          label: 'Data',
          align: 'center',
          field: row => row.liquidacao,
          sortable: true,
        },
        {
          name: 'descricao',
          required: false,
          align: 'center',
          label: 'Descrição',
          field: row => row.descricao,
          sortable: true,
        },
        {
          name: 'valor_liquidado',
          required: false,
          align: 'center',
          label: 'Valor',
          field: row => parseFloat(row.valor_liquidado),
          sortable: true,
        },
        {
          name: 'expandir',
          headerStyle: 'width: 40px; padding: 8px;',
        },
      ],
      paginationLancamentosSistema: {
        sortBy: 'id',
        descending: true,
        page: 1,
        rowsPerPage: 0,
      },
      selectedLancamentosSistema: [],
      hasVideo: false,
      dateRange: {
        min: 1, max: 1
      },
      pan: '',
      windowWidth: window.innerWidth,
    };
  },
  methods:{
    // Gerais
    loadFiltros() {
      this.filtros.conta_id = this.$route.query.conta_id;
      this.filtros.arquivo_id = this.$route.query.arquivo_id;

      const dtiniDate = moment(this.$route.query.dtini,'YYYY-MM-DD');
      const dtfimDate = moment(this.$route.query.dtfim,'YYYY-MM-DD');
      const dtiniExtratoDate = moment(this.$route.query.dtini_extrato,'YYYY-MM-DD');

      if (dtiniDate.isValid()) {
        this.filtros.dtiniSistema = dtiniDate.format('DD/MM/YYYY');
      }
      if (dtfimDate.isValid()) {
        this.filtros.dtfimSistema = dtfimDate.format('DD/MM/YYYY');
      }
      if (dtiniExtratoDate.isValid()) {
        this.filtros.dtiniExtrato = dtiniExtratoDate.format('DD/MM/YYYY');
        this.filtros.dtfimExtrato = dtiniExtratoDate.format('DD/MM/YYYY');
      }
    },
    loadConciliacao(atualizar = false) {
      if (this.loadingConciliacao) return;

      if (atualizar) {
        this.loadingExtratos = true;
        this.loadingLancamentosSistema = true;
      } else {
        this.loadingConciliacao = true;
      }

      const { arquivo_id, conta_id, dtiniSistema, dtfimSistema, dtiniExtrato, dtfimExtrato } = this.filtros;

      const url = `/financeiro/conciliar/${conta_id}`;
      const queryParams = {
        action: 'importar',
        arquivo_id,
      };

      const dtiniDate = moment(dtiniSistema,'DD/MM/YYYY');
      const dtfimDate = moment(dtfimSistema,'DD/MM/YYYY');
      const dtiniExtratoDate = moment(dtiniExtrato,'DD/MM/YYYY');
      const dtfimExtratoDate = moment(dtfimExtrato,'DD/MM/YYYY');

      if (dtiniDate.isValid()) {
        queryParams.dtinisistema = dtiniDate.format('YYYY-MM-DD');
      }
      if (dtfimDate.isValid()) {
        queryParams.dtfimsistema = dtfimDate.format('YYYY-MM-DD');
      }
      if (dtiniExtratoDate.isValid()) {
        queryParams.dtini_extrato = dtiniExtratoDate.format('YYYY-MM-DD');
      }
      if (dtfimExtratoDate.isValid()) {
        queryParams.dtfim_extrato = dtfimExtratoDate.format('YYYY-MM-DD');
      }

      axios
        .get(url, { params: queryParams })
        .then(res => {
          if (res.data.is_anterior) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });

            this.$router.back();

            return;
          }

          if (!res.data.success) {
            throw res.data;
          }

          const dtini = moment(res.data.dtini,'YYYY/MM/DD').format('DD/MM/YYYY');
          const dtfim = moment(res.data.dtfim,'YYYY/MM/DD').format('DD/MM/YYYY');
          const dtiniConciliacao = moment(res.data.dtini_conciliacao,'YYYY/MM/DD').format('DD/MM/YYYY');
          const dtfimConciliacao = moment(res.data.dtfim_conciliacao,'YYYY/MM/DD').format('DD/MM/YYYY');

          if (!this.filtros.dtiniSistema) {
            this.filtros.dtiniSistema = dtini;
            this.filtros.dtfimSistema = dtfim;
          }

          if (!this.filtros.dtiniExtrato) {
            this.filtros.dtiniExtrato = dtini;
            this.filtros.dtfimExtrato = dtini;
          }

          this.filtros.buscaLancamentosSistema = '';

          this.arquivo = res.data.arquivo;
          this.contaExtrato = res.data.contaExtrato;
          this.dias_conciliados = res.data.dias_conciliados;
          this.meta = {
            countRegistros: res.data.countRegistros,
            dtini: dtiniConciliacao,
            dtfim: dtfimConciliacao,
          };

          this.extratos = res.data.extratos;
          this.checkedExtratos = [];

          this.lancamentosSistema = res.data.movcontas;
          this.selectedLancamentosSistema = [];

          if (atualizar) {
            this.loadingExtratos = false;
            this.loadingLancamentosSistema = false;
          } else {
            this.loadingConciliacao = false;
          }

          if (res.data.importado) {
            this.createDialogImportado(res.data.arquivo);
          }
        })
        .catch(() => {
          if (atualizar) {
            this.loadingExtratos = false;
            this.loadingLancamentosSistema = false;
          } else {
            this.loadingConciliacao = false;
          }

          this.$q.notify({
            message: 'Não foi possivel conectar com arquivo',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    atualizarConciliacao() {
      this.loadConciliacao(true);
    },
    deleteConciliacao() {
      const palavraConfirmacao = 'Confirmo a exclusão';

      this.$q.dialog({
        title: "Desfazer conciliação",
        message: `Deseja realmente desconciliar este arquivo?
          <br>Os lançamentos serão marcados como não conciliados.
          <br>Digite <strong style="color: #EA4335;">"${palavraConfirmacao}"</strong> para continuar`,
        cancel: 'Cancelar',
        persistent: true,
        html: true,
        prompt: {
          type: 'text',
          model: '',
          isValid: val => val === palavraConfirmacao,
        },
      })
        .onOk((val) => {
          Loading.show();
        })
        .onOk((val) => {
          const { arquivo_id, conta_id } = this.filtros;
          const url = `/financeiro/conciliar/${conta_id}`;

          const params = {
            action: 'excluir',
            confirmacao: val,
            arquivo_id,
            conta_id,
          };

          axios
            .post(url, params)
            .then(res => {
              Loading.hide();

              if (res.data.success) {
                this.$q.notify({
                  message: res.data.message,
                  color: 'green',
                  position: 'top-right',
                });

                this.$router.push('/arquivos?tipo=extrato');
              } else {
                this.$q.notify({
                  message: res.data.message,
                  color: 'orange',
                  position: 'top-right',
                });
              }
            })
            .catch(() => {
              this.$q.notify({
                message: 'Não foi possivel desconciliar o arquivo.',
                color: 'orange',
                position: 'top-right',
              });
            });
        });
    },
    // Extrato
    changeTipoExtrato(tipo) {
      this.filtros.tipoExtrato = tipo;
    },
    selecionarExtrato(extrato, event) {
      if (this.loadingExtratoSelecionado) return;
      if (event.detail === 2) return;

      extrato = JSON.parse(JSON.stringify(extrato));

      extrato.lancamentosSistemaConciliados = [];
      extrato.extratosConciliados = [];

      const extratoIndex = this.selectedExtratos.findIndex(item => item.id === extrato.id);

      if (extratoIndex !== -1) {
        this.selectedExtratos.splice(extratoIndex, 1);
        return;
      }

      if (!extrato.conciliado) {
        this.selectedExtratos.push(extrato);
        return;
      }

      const { arquivo_id, conta_id } = this.$route.query;
      this.loadingExtratoSelecionado = true;

      axios
        .get(`/financeiro/conciliar/${conta_id}`, {
          params: {
            action: 'conciliados',
            arquivo_id: arquivo_id,
            extrato_id: extrato.id,
          }
        })
        .then(res => {
          if (!res.data.success) throw res.data;

          extrato.extratosConciliados = res.data.extratos_conciliados;
          extrato.lancamentosSistemaConciliados = res.data.conciliados;
          this.loadingExtratoSelecionado = false;

          this.selectedExtratos.push(extrato);
        })
        .catch(() => {
          this.loadingExtratoSelecionado = false;
          this.$q.notify({
            message: 'Não foi possivel carregar as informações deste extrato',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    selectAllExtratos() {
      this.selectedExtratos = [];
      if (this.allSelected) {
        this.allSelected = false;
        return;
      }

      const extratos = this.extratosFiltrado.map(extrato => {
        if (extrato.lancamentosSistemaConciliados === undefined) {
          extrato.lancamentosSistemaConciliados = [];
        }

        if (extrato.extratosConciliados === undefined) {
          extrato.extratosConciliados = [];
        }

        return extrato;
      });
      this.selectedExtratos = extratos;
      this.allSelected = true;
    },
    resetSelectedExtratos() {
      this.selectedExtratos = [];
      this.allSelected = false;
    },
    buscarValorExtrato(valor) {
      this.filtros.buscaLancamentosSistema = parseFloat(valor)
        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        .replace('-', '')
        .replace(' ', ' ');
    },
    editarLancamentoSistema() {
      const extrato = this.selectedLancamentosSistema[0];

      let filters = {
        dtiniSaldoFinal: moment(this.meta.dtini, 'DD/MM/YYYY').format('DD-MM-YYYY'),
      };

      this.$store.dispatch('saldoExtrato', filters);

      let url = '/financeiro/edit/';

      if (extrato.id) {
        url += extrato.id;
      }

      const params = {
        gateway_id: extrato.gateway_id,
        liquidacao: extrato.liquidacao,
        conciliado: extrato.conciliado ? 1 : 0,
      };

      if (extrato.agrupada) {
        params.dtmovimento = extrato.dtmovimento;
      }

      axios
        .get(url, { params })
        .then(res => {
          res.data.movconta.agrupado = extrato.agrupada;
          res.data.movconta.liquidacao = moment(res.data.movconta.liquidacao, 'YYYY/MM/DD').format('DD/MM/YYYY');

          if(res.data.movconta && res.data.movconta.dtmovimento){
            res.data.movconta.dtmovimento = moment(res.data.movconta.dtmovimento, 'YYYY/MM/DD').format('DD/MM/YYYY');
          }

          const { movconta } = res.data;
          movconta.editarConciliado = true;

          this.$store.commit('extratoItem', movconta);
          this.$store.commit('OpenDialogEditarExtrato', true);
          this.$store.dispatch('contas');
          this.$store.dispatch('planoContas', parseInt(res.data.movconta.tipo) === 1 ? 'D' : 'C');
        })
        .catch(() => {
          this.$q.notify({
            message: 'Ocorreu um erro ao tentar efetuar uma alteração!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    getIsExtratoSelecionado(extratoId) {
      return this.selectedExtratos.some(item => item.id === extratoId);
    },
    getIsExtratoConciliado(extratoId) {
      return this.selectedExtratos.some(item => item.extratosConciliados.includes(extratoId));
    },
    changeDtExtrato(dtextrato = null) {
      this.selectedExtratos = [];
      this.checkedExtratos = [];
      if (!dtextrato) {
        const dtiniExtrato = this.dias_conciliados[0].dtmovimento;
        const dtfimExtrato = this.dias_conciliados[this.dias_conciliados.length - 1].dtmovimento;

        this.filtros.dtiniExtrato = dtiniExtrato;
        this.filtros.dtfimExtrato = dtfimExtrato;
        return;
      }

      this.filtros.dtiniExtrato = dtextrato;
      this.filtros.dtfimExtrato = dtextrato;
    },
    changeFilterDtExtrato(tipo) {
      this.resetSelectedExtratos();
      this.checkedExtratos = [];
      const dtiniExtrato  = moment(this.filtros.dtiniExtrato, 'DD/MM/YYYY');
      const dtfimExtrato  = moment(this.filtros.dtfimExtrato, 'DD/MM/YYYY');

      if (tipo === 'prev') {
        dtiniExtrato.subtract(1, 'days');
        dtfimExtrato.subtract(1, 'days');
      }

      if (tipo === 'next') {
        dtiniExtrato.add(1, 'days');
        dtfimExtrato.add(1, 'days');
      }

      this.filtros.dtiniExtrato = dtiniExtrato.format('DD/MM/YYYY');
      this.filtros.dtfimExtrato = dtfimExtrato.format('DD/MM/YYYY');
      this.atualizarParametrosUrl();
    },
    selecionarDtExtrato(ref) {
      this.$refs[ref].hide();

      this.atualizarParametrosUrl();
    },
    filterDtExtratoOptions(date) {
      const dtiniExtrato = this.dias_conciliados[0].dtmovimento;
      const dtfimExtrato = this.dias_conciliados[this.dias_conciliados.length - 1].dtmovimento;

      const dtiniIso = dtiniExtrato.split('/').reverse().join('/');
      const dtfimIso = dtfimExtrato.split('/').reverse().join('/');

      return date >= dtiniIso && date <= dtfimIso;
    },
    // Lançamentos do sistema
    loadLancamentosSistema() {
      if (this.loadingLancamentosSistema) return;

      this.loadingLancamentosSistema = true;

      const { arquivo_id, conta_id, dtiniSistema, dtfimSistema, dtextrato } = this.filtros;

      const url = `/financeiro/conciliar/${conta_id}`;
      const queryParams = {
        action: 'importar',
        arquivo_id,
      };

      const dtiniDate = moment(dtiniSistema,'DD/MM/YYYY');
      const dtfimDate = moment(dtfimSistema,'DD/MM/YYYY');
      const dtextratoDate = moment(dtextrato,'DD/MM/YYYY');

      if (dtiniDate.isValid()) {
        queryParams.dtinisistema = dtiniDate.format('YYYY-MM-DD');
      }
      if (dtfimDate.isValid()) {
        queryParams.dtfimsistema = dtfimDate.format('YYYY-MM-DD');
      }
      if (dtextratoDate.isValid()) {
        queryParams.dtextrato = dtextratoDate.format('YYYY-MM-DD');
      }

      this.selectedLancamentosSistema = [];

      axios
        .get(url, { params: queryParams })
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          this.filtros.dtiniSistema = moment(res.data.dtiniSistema,'YYYY-MM-DD').format('DD/MM/YYYY');
          this.filtros.dtfimSistema = moment(res.data.dtfimSistema,'YYYY-MM-DD').format('DD/MM/YYYY');
          this.lancamentosSistema = res.data.movcontas;

          this.loadingLancamentosSistema = false;
        })
        .catch(() => {
          this.loadingLancamentosSistema = false;
          this.$q.notify({
            message: 'Não foi possivel carregar os lançamentos do sistema',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    changeFilterPeriodo(tipo) {
      const dtini = moment(this.filtros.dtiniSistema, 'DD/MM/YYYY');
      const dtfim = moment(this.filtros.dtfimSistema, 'DD/MM/YYYY');

      if (tipo === 'prev') {
        dtini.subtract(1, 'days');
        dtfim.subtract(1, 'days');
      }

      if (tipo === 'next') {
        dtini.add(1, 'days');
        dtfim.add(1, 'days');
      }

      this.filtros.dtiniSistema = dtini.format('DD/MM/YYYY');
      this.filtros.dtfimSistema = dtfim.format('DD/MM/YYYY');

      this.loadLancamentosSistema();
      this.atualizarParametrosUrl();
    },
    changeTipoLancamento(tipo) {
      this.filtros.tipoLancamento = tipo;
    },
    selecionarDtsistema(ref) {
      this.$refs[ref].hide();

      this.loadLancamentosSistema();
      this.atualizarParametrosUrl();
    },
    exibirLancamentos (lancamento) {
      const lancamentoIndex = this.lancamentosSistema.findIndex(
        row => lancamento.key === `${row.id}${row.descricao}${row.dtmovimento}${row.liquidacao}`
      );

      // Caso não exista a propriedade
      if (lancamento.expand === undefined) {
        lancamento.expand = false;
      }

      // Para quando for fechar o detalhamento
      if (lancamento.expand) {
        lancamento.expand = false;
        return;
      }

      const { arquivo_id, conta_id } = this.$route.query;

      this.$set(this.lancamentosSistema[lancamentoIndex], 'loadingDetalhamento', true);

      axios
        .get(`/financeiro/conciliar/${conta_id}`, {
          params: {
            action: 'detalhamento',
            arquivo_id: arquivo_id,
            gateway_id: lancamento.row.gateway_id,
            liquidacao: lancamento.row.liquidacao,
            conciliado: lancamento.row.conciliado,
            dtmovimento: lancamento.row.dtmovimento,
          },
        })
        .then(res => {
          if (!res.data.success) {
            throw res.data;
          }

          this.$set(this.lancamentosSistema[lancamentoIndex], 'lancamentos', res.data.lancamentos);
          this.$set(this.lancamentosSistema[lancamentoIndex], 'loadingDetalhamento', false);

          lancamento.expand = !lancamento.expand;
        })
        .catch(() => {
          this.$set(this.lancamentosSistema[lancamentoIndex], 'loadingDetalhamento', false);

          this.$q.notify({
            message: 'Não foi possivel carregar os lançamentos do sistema',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    async conciliar(dadosConciliacao = null) {
      this.loadingConciliar = true;

      const { arquivo_id, conta_id } = this.$route.query;
      const idsSelectedExtratos = this.checkedExtratos.map(extrato => extrato.id);
      let idsLancamentosSistema = [];

      if (!dadosConciliacao) {
        idsLancamentosSistema = await this.getIdsLancamentosSistema();
      }

      let data = {
        action: 'conciliar',
        confirmado: null,
        confirmar_diferenca: false,
        extratos_ids: idsSelectedExtratos,
        movcontas_ids: idsLancamentosSistema,
      };

      if (dadosConciliacao) {
        data = dadosConciliacao;
      }

      axios
        .post(`/financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}`, data)
        .then(res => {
          this.loadingConciliar = false;

          if (res.data.confirmar_dtmovimento) {
            this.$q.dialog({
              title: 'Realizar Conciliação' ,
              message: res.data.message,
              cancel: "Não",
              persistent: true,
            })
            .onOk(() => {
              Loading.hide();
            })
            .onOk(() => {
              data.confirmado = true;
              this.conciliar(data);
            })
            .onCancel(() => {
              Loading.hide();
            })
            .onCancel(() => {
              data.confirmado = false;
              this.conciliar(data);
            });
            return;
          }

          if (res.data.tem_diferenca) {
            const lancamentoDiferenca = res.data.lancamento_diferenca;
            lancamentoDiferenca.fromConciliacao = true;
            lancamentoDiferenca.dadosConciliacao = data;
            lancamentoDiferenca.dtmovimento = moment(lancamentoDiferenca.dtmovimento, 'DD/MM/YYYY').format('YYYY/MM/DD');

            return this.lancarDiferencaExtrato(lancamentoDiferenca);
          }

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.loadConciliacao(true);
        })
        .catch(() => {
          this.loadingConciliar = false;

          this.$q.notify({
            message: 'Não foi possivel conciliar os lançamentos',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    async desconciliar() {
      this.loadingDesconciliar = true;

      const { arquivo_id, conta_id } = this.$route.query;

      const idsLancamentosSistema = await this.getIdsLancamentosSistema();

      let data = {
        action: 'desconciliar',
        movcontas_ids: idsLancamentosSistema,
      };

      axios
        .post(`/financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}`, data)
        .then(res => {
          this.loadingDesconciliar = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.loadConciliacao(true);
        })
        .catch(() => {
          this.loadingDesconciliar = false;

          this.$q.notify({
            message: 'Não foi possivel desconciliar os lançamentos',
            color: 'orange',
            position: 'top-right',
          });
        });
    },
    async getIdsLancamentosSistema() {
      const { arquivo_id, conta_id } = this.$route.query;

      return (await Promise.all(
        this.selectedLancamentosSistema.map(async lancamento => {
          if (!lancamento.agrupada) {
            return new Promise(resolve => resolve([lancamento.id]));
          }

          const response = await axios
            .get(`/financeiro/conciliar/${conta_id}`, {
              params: {
                action: 'detalhamento',
                arquivo_id: arquivo_id,
                gateway_id: lancamento.gateway_id,
                liquidacao: lancamento.liquidacao,
                conciliado: lancamento.conciliado,
                dtmovimento: lancamento.dtmovimento,
              },
            });

          const idsLancamentos = response.data.lancamentos.map(lancamento => lancamento.id);
          return idsLancamentos;
        })
      )).flat();
    },
    buscarLancamentoExtrato() {
      const extrato = JSON.parse(JSON.stringify(this.selectedExtratos[0]));

      this.$store.commit('checkedExtratos', [extrato]);
      this.$store.commit('OpenDialogSearch', true);
    },
    incluirLancamentoExtrato(extrato = null) {
      let extratos = [];
      if (!extrato) {
        extratos = this.selectedExtratos;
      } else {
        extratos = [JSON.parse(JSON.stringify(extrato))];
      }

      this.$store.dispatch('contas');
      this.$store.commit('selectedExtratos', extratos);
      this.$store.commit('OpenDialogIncluirConta', true);
    },
    lancarDiferencaExtrato(extrato = null) {
      if (!extrato) {
        extrato = this.extratoSelecionado;
      }

      extrato = JSON.parse(JSON.stringify(extrato));
      extrato.isDiferenca = true;
      extrato.valor = extrato.valor - extrato.total_conciliada;

      this.$store.commit('selectedExtrato', extrato);
      this.$store.commit('OpenDialogLancarDiferenca', true);
    },
    ignorarReativarExtrato() {
      const extrato = JSON.parse(JSON.stringify(this.selectedExtratos[0]));

      this.$q.dialog({
        title: `Sua conta será ${extrato.ignorado ? 'reativada' : 'ignorada'}`,
        message: 'Tem certeza que deseja fazer isto?',
        cancel: 'Cancelar',
        persistent: true,
      })
      .onOk(() => {
        Loading.show();
      })
      .onOk(() => {
        const { arquivo_id, conta_id } = this.$route.query;

        axios
          .post(`/financeiro/conciliar/${conta_id}?arquivo_id=${arquivo_id}`, {
            action: 'ignorar',
            extrato_id: extrato.id,
          })
          .then(res => {
            this.$q.notify({
              message: res.data.message,
              color: res.data.success ? 'green' : 'orange',
              position: 'top-right',
            });

            if (!res.data.success) return;

            this.atualizarConciliacao();
          })
          .catch(() => {
            this.$q.notify({
              message: `Não foi possivel ${extrato.ignorado ? 'reativar' : 'ignorar'} o lançamento`,
              color: 'red',
              position: 'top-right',
            });
          });
      })
      .onCancel(() => {
        Loading.hide();
      })
      .onDismiss(() => {
        Loading.hide();
      });
    },
    // Range de dias
    getDayLabel(day) {
      return moment(day.dtmovimento, 'DD/MM/YYYY').format('DD');
    },
    changeDateRange(range) {
      this.filtros.dtiniExtrato = this.dias_conciliados[range.min - 1].dtmovimento;
      this.filtros.dtfimExtrato = this.dias_conciliados[range.max - 1].dtmovimento;
    },
    getCorRange(diaExtrato) {
      let cor = null;
      const qtdConciliado = diaExtrato.extratos_quantidade_conciliados;
      const qtdNaoConciliado = diaExtrato.extratos_quantidade_nao_conciliados;
      const qtdMovcontasConciliado = diaExtrato.movcontas_quantidade_nao_conciliados;

      if (qtdConciliado > 0) {
        cor = 'green';
      }
      if (qtdNaoConciliado > 0 || qtdMovcontasConciliado > 0) {
        cor = 'pink';
      }

      if (cor) return cor;

      return 'grey';
    },
    getDayInfo(diaExtrato, conciliado) {
      if (conciliado) {
        const { extratos_quantidade_conciliados, extratos_valor_conciliados } = diaExtrato;
        const quantidade = extratos_quantidade_conciliados;
        const valor = parseFloat(extratos_valor_conciliados);

        const formatedValor = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        return `Conciliados: (${quantidade}) ${formatedValor}`;
      }

      const {
        extratos_quantidade_nao_conciliados,
        movcontas_quantidade_nao_conciliados,
        extratos_valor_nao_conciliados,
        movcontas_valor_nao_conciliados
      } = diaExtrato;
      const quantidade = extratos_quantidade_nao_conciliados + movcontas_quantidade_nao_conciliados;
      const valor = parseFloat(extratos_valor_nao_conciliados) + parseFloat(movcontas_valor_nao_conciliados);
      const formatedValor = valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});

      return `Não Conciliados: (${quantidade}) ${formatedValor}`;
    },
    // Outros
    updateClassName() {
      if (this.expanded) {
        this.$refs.cardInfoTotal.classList.remove('d-none');
        this.$refs.cardInfoTotal.classList.add('d-block');
      } else {
        this.$refs.cardInfoTotal.classList.remove('d-block');
        this.$refs.cardInfoTotal.classList.add('d-none');
      }
    },
    atualizarParametrosUrl() {
      const query = this.$route.query;
      let newQueryString = '?';

      newQueryString += `conta_id=${query.conta_id}`;
      newQueryString += `&arquivo_id=${query.arquivo_id}`;

      const dtiniSistema = moment(this.filtros.dtiniSistema, 'DD/MM/YYYY');
      const dtfimSistema = moment(this.filtros.dtfimSistema, 'DD/MM/YYYY');
      const dtiniExtrato = moment(this.filtros.dtiniExtrato, 'DD/MM/YYYY');
      const dtfimExtrato = moment(this.filtros.dtfimExtrato, 'DD/MM/YYYY');

      if (dtiniSistema.isValid()) {
        newQueryString += `&dtini=${dtiniSistema.format('YYYY-MM-DD')}`;
      }
      if (dtfimSistema.isValid()) {
        newQueryString += `&dtfim=${dtfimSistema.format('YYYY-MM-DD')}`;
      }
      if (dtiniExtrato.isValid()) {
        newQueryString += `&dtini_extrato=${dtiniExtrato.format('YYYY-MM-DD')}`;
      }
      if (dtfimExtrato.isValid()) {
        newQueryString += `&dtfim_extrato=${dtfimExtrato.format('YYYY-MM-DD')}`;
      }

      window.history.replaceState({}, '', newQueryString);
    },
    createDialogImportado(arquivo) {
      const message = `${arquivo.count_registrados} registros no arquivo.<br/>
        ${arquivo.count_notfound} novos, ${arquivo.count_liquidados} repetidos.`;

      this.$q.dialog({
        title: message,
        class: 'bg-positive text-white',
        html: true,
        ok: {
          'text-color': 'green',
          color: 'white',
        },
      });
    },
    openModalIntroducao() {
      this.$store.dispatch("loadManual", "conciliacao");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "conciliacao");

      if (!this.videosManuais.video_conciliacao) {
        this.openModalIntroducao();
      }
    },
    setLoadingExtratos(value) {
      this.loadingExtratos = value;
    }
  },
  computed: {
    ...mapGetters([
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    // Extrato
    extratosFiltrado() {
      this.setLoadingExtratos(true);
      let extratos = this.extratos;
      let {
        dtiniExtrato,
        dtfimExtrato,
        tipoExtrato,
        buscaNomenclaturaExtrato,
      } = this.filtros;

      const filtroData = dtiniExtrato && dtfimExtrato;
      if (!filtroData) {
        // Caso o filtro não esteja preenchido, é usado o primeiro dia
        dtiniExtrato = this.dias_conciliados[0].dtmovimento;
        dtfimExtrato = dtiniExtrato;
      }

      // Formata de dd/mm/yyyy para yyyy-mm-dd
      const dtiniIso = dtiniExtrato.split('/').reverse().join('-');
      const dtfimIso = dtfimExtrato.split('/').reverse().join('-');

      const dtiniMovimento = Date.parse(dtiniIso);
      const dtfimMovimento = Date.parse(dtfimIso);

      const extratosFiltrados = extratos.filter(extrato => {
        switch (tipoExtrato.value) {
          case 'conciliados':
            if (!extrato.conciliado) return false;
            break;
          case 'nao_conciliados':
            if (extrato.conciliado || extrato.ignorado) return false;
            break;
          case 'ignorados':
            if (!extrato.ignorado) return false;
            break;
          default:
            if (extrato.ignorado) return false;
        }

        if (filtroData) {
          // Substitui a barra por traço para que o parse funcione
          let dtMovimento = Date.parse(extrato.dtmovimento.replace(/\//g, '-'));
          if (dtMovimento < dtiniMovimento || dtMovimento > dtfimMovimento) {
            return false;
          }
        }

        if (buscaNomenclaturaExtrato !== '' && buscaNomenclaturaExtrato.length >= 2) {
          const descricao = extrato.descricao.toLowerCase();
          const busca = buscaNomenclaturaExtrato.toLowerCase();

          if (!descricao.includes(busca)) return false;
        }

        return true;
      });

      this.setLoadingExtratos(false);
      this.resetSelectedExtratos();
      return extratosFiltrados;
    },
    extratoSelecionado() {
      if (this.selectedExtratos.length !== 1) return null;

      return this.selectedExtratos[0];
    },
    // Lançamentos do Sistema
    /**
     * Busca pelo texto digitado na pesquisa, o texto pode ser um valor ou uma descrição
     * Se for valor (ter 'R$'), busca de duas formas:
     * 1 - Direramente pelo número no formato de texto
     * 2 - Pelo valor aproximado do número
     */
    lancamentosSistemaFiltrado() {
      let { buscaLancamentosSistema, tipoLancamento } = this.filtros;
      let busca = buscaLancamentosSistema;
      const porcentagemValorBusca = 0.05;

      return this.lancamentosSistema.filter(lancamento => {
        if (lancamento.descricao === "Saldo Anterior") return true;
        if (tipoLancamento.value === 'conciliados' && !lancamento.conciliado) return false;
        if (tipoLancamento.value === 'nao_conciliados' && lancamento.conciliado) return false;

        if (busca && busca.length >= 3) {
          if (!(busca.includes('R$'))) {
            return lancamento.descricao.toLowerCase().includes(busca.toLowerCase());
          }

          const valorLancamento = Math.abs(parseFloat(lancamento.valor_liquidado));
          const buscaNumber = parseFloat(busca.replace(/[^\d,-]/g, '').replace(',', '.'));
          const valorLancamentoMin = valorLancamento - (valorLancamento * porcentagemValorBusca);
          const valorLancamentoMax = valorLancamento + (valorLancamento * porcentagemValorBusca);

          return valorLancamento
            .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            .replace('-', '')
            .replace(' ', ' ') // O primeiro caracter não é um espaço comum
            .includes(busca.replace('-', ''))
            || (buscaNumber >= valorLancamentoMin && buscaNumber <= valorLancamentoMax);
        }

        return true;
      });
    },
    // Card de informações
    valorTotalExtrato() {
      return this.extratosFiltrado
        .reduce((total, extrato) => parseFloat(extrato.valor) + total, 0.00);
    },
    valorTotalExtratoSelecionado() {
      return this.checkedExtratos
        .reduce((total, extrato) => parseFloat(extrato.valor) + total, 0.00);
    },
    valorTotalLancamentosSistema() {
      return this.lancamentosSistema
        .filter(movconta => movconta.tipo !== 'Saldo')
        .reduce((total, movconta) => parseFloat(movconta.valor_liquidado) + total, 0.00);
    },
    valorTotalLancamentosSistemaSelecionado() {
      return this.selectedLancamentosSistema
        .filter(movconta => movconta.tipo !== 'Saldo')
        .reduce((total, movconta) => parseFloat(movconta.valor_liquidado) + total, 0.00);
    },
    valorDiferencaLancamentosTotal() {
      return this.valorTotalExtrato - this.valorTotalLancamentosSistema;
    },
    valorDiferencaLancamentosSelecionados() {
      return this.valorTotalExtratoSelecionado - this.valorTotalLancamentosSistemaSelecionado;
    },
    // Estado de botões de extrato
    tipoBotaoIgnorarReativarExtrato() {
      const extratoSelecionado = this.selectedExtratos[0];
      if (!extratoSelecionado) {
        return 'ignorar';
      }

      return extratoSelecionado.ignorado ? 'reativar' : 'ignorar';
    },
    disableBotaoBuscarLancamentoExtrato() {
      return this.selectedExtratos.length !== 1;
    },
    disableBotaoIncluirLancamentoExtrato() {
      return this.selectedExtratos.length === 0;
    },
    disableBotaoLancarDiferencaExtrato() {
      return !this.extratoSelecionado
        || (this.extratoSelecionado && this.extratoSelecionado.valor == this.extratoSelecionado.total_conciliada);
    },
    disableBotaoIgnorarReativarExtrato() {
      return this.selectedExtratos.length !== 1;
    },
    // Estado de botões de lançamentos do sistema
    disableBotaoEditarLancamentosSistema() {
      return this.selectedLancamentosSistema.length !== 1 || this.selectedLancamentosSistema[0].tipo === 'Saldo';
    },
    disableBotaoConciliarLancamentosSistema() {
      return this.selectedLancamentosSistema.length === 0
        || this.checkedExtratos.length === 0;
    },
    disableBotaoDesconciliarLancamentosSistema() {
      return this.selectedLancamentosSistema.filter(lancamento => lancamento.conciliado).length === 0;
    },
    // Outros
    dataBuscarContas() {
      const data = {
        dtini: null,
        dtfim: null,
      };

      const dtini = moment(this.meta.dtini, 'DD/MM/YYYY');
      const dtfim = moment(this.meta.dtfim, 'DD/MM/YYYY');

      if (dtini.isValid()) {
        data.dtini = dtini.format("YYYY-MM-DD");
      }
      if (dtfim.isValid()) {
        data.dtfim = dtfim.format("YYYY-MM-DD");
      }

      if (this.selectedExtratos.length === 1) {
        data.selectedExtrato = this.selectedExtratos[0];
      }

      return data;
    },
    styleRangeInput() {
      return {
        margin: '0 auto',
        width: this.rangeWidth,
      };
    },
    rangeWidth() {
      // Função que calcula a largura do input range de acordo com a quantidade de dias
      const maxWidth = 65;
      const referenceItensCount = 30;
      const itensCount = this.dias_conciliados.length;

      const proportionalWidth = (itensCount * maxWidth) / referenceItensCount;
      let finalWidth = Math.min(Math.max(proportionalWidth, 10), 100);

      if (this.windowWidth < 1420) {
        finalWidth *= 1.2;
      }

      if (this.windowWidth < 1280) {
        finalWidth *= 1.2;
      }

      return `${finalWidth}%`;
    },
  },
  watch: {
    ['filtros.dtiniExtrato' || 'filtros.dtfimExtrato'] () {
      this.atualizarParametrosUrl();
    },
  },
  mounted(){
    this.loadFiltros();
    this.loadConciliacao();
    this.verificarPrimeiroVideo();

    bus.$on('conciliar', dadosConciliacao => {
      this.conciliar(dadosConciliacao);
    });
    bus.$on('atualizarConciliacao', () => {
      this.atualizarConciliacao();
    });

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  destroyed() {
    bus.$off('conciliar');
    bus.$off('atualizarConciliacao');
  },
};
</script>

<style lang="stylus" scoped>
h1 {
  font-weight: 300;
  font-size: 1.75rem;
  line-height: 1.75rem;
}

table tr:hover {
  .botao-expandir {
    color: #fff !important;
    background-color: #9e9e9e !important;
  }
}

.lancamento-conciliado {
  background: #c8e6c9;
  cursor: pointer;
}

.extrato-selecionado {
  color: white;
  background-color: #F2C037;
  cursor: pointer;
}

.q-card.card-info {
  width: 85%;
  min-height: 42px;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  left: 50%;
  transform: translateX(-50%);

  .absolute-right {
    height: min-content;

    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}

</style>
